import barba from "@barba/core";
import { delay, stripNaughtyWords, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { bubbles } from "../components/bubbles";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";

const trial_5_3 = () => {
  delay(500).then(() => {

  // --------------------------------------------------
  // question variables
  // --------------------------------------------------
  let questionIndex = 5;
  let questionPart = 2;
  let questionPartB = 3;

         // pagination
         paginationControls(questionIndex);

  // --------------------------------------------------
  // sanitise textarea
  // --------------------------------------------------
  const textarea = document.getElementById("working");
  const textareaB = document.getElementById("working2");
  if (textarea) {
    stripNaughtyWords(textarea);
  }
  if (textareaB) {
    stripNaughtyWords(textareaB);
  }

  // --------------------------------------------------
  // Functions
  // --------------------------------------------------
  cluesInit("sfx_newspaper.mp3", "spellbound");
  bubbles();

  // --------------------------------------------------
  // modify storage
  // --------------------------------------------------

  // get localStorage
  let storedValue = getStoreQuestionValue(
    "assessment",
    "questions",
    questionIndex,
    questionPart
  );
  if (storedValue) {
    textarea.value = storedValue;
  }

  let storedValueB = setStoreQuestionValue(
    "assessment",
    "questions",
    questionIndex,
    questionPartB
  );
  if (storedValueB) {
    textareaB.value = storedValueB;
  }

  // set localStorage
  const setLocalStorage = () => {
    setStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart,
      textarea.value
    );
    setStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPartB,
      textareaB.value
    );
  };

  // --------------------------------------------------
  // slide navigation
  // --------------------------------------------------
  const saveAndContinue = (link) => {
    setLocalStorage();
    barba.go(link);
  };
  const next = document.querySelector(".js-next");
  const prev = document.querySelector(".js-prev");
  if (prev) {
    prev.addEventListener("click", (e) => {
      e.preventDefault();
      saveAndContinue(e.target.href);
    });
  }
  if (next) {
    next.addEventListener("click", (e) => {
      e.preventDefault();
      saveAndContinue(e.target.href);
    });
  }


});
};

export { trial_5_3 };
