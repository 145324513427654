import barba from "@barba/core";
import { delay, paginationControls } from "../components/utils";
import { bubbles } from "../components/bubbles";

const trial_10_4 = () => {

    delay(500).then(() => {
        bubbles();


            // --------------------------------------------------
    // question variables
    // --------------------------------------------------
    let questionIndex = 10;

    // pagination
    paginationControls(questionIndex);


    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const saveAndContinue = (link) => { 
        barba.go(link); 
    };
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            saveAndContinue(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            saveAndContinue(e.target.href);
        });
    }

});
    
};

export { trial_10_4 };
