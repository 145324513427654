import barba from '@barba/core'
import { delay, stripNaughtyWords } from '../components/utils'
import { setStoreObjValue, getStoreObjValue } from '../components/stores'

const initMethodsSetup = () => {
  delay(100).then(() => {
    if (isDebugMode) {
      console.log('Set up assessment...')
    }
    // get values from the store
    document.querySelector('#student').value = getStoreObjValue(
      'student',
      'name'
    )
    document.querySelector('#student_id').value = getStoreObjValue(
      'student',
      'id'
    )
    document.querySelector('#centre_name').value = getStoreObjValue(
      'assessment',
      'centre'
    )
    document.querySelector('#subject').value = getStoreObjValue(
      'assessment',
      'subject'
    )
    document.querySelector('#paper').value = getStoreObjValue(
      'assessment',
      'paper'
    )

    // check for naughty words and strip from student name
    let studentName = document.getElementById('student')
    stripNaughtyWords(studentName)

    // on form submission
    let setupForm = document.querySelector('#setupForm')
    if (setupForm) {
      setupForm.addEventListener('submit', (e) => {
        e.preventDefault()
        let formData = new FormData(setupForm)
        document.querySelectorAll('.hint').forEach((el) => {
          el.classList.remove('block')
          el.classList.add('hidden')
        })
        let myArray = ['subject', 'centre_name', 'paper']
        let hasInvalidElems = false
        myArray.forEach((el) => {
          let elem = document.getElementById(el)
          if (elem.value == '') {
            // show errors
            elem.nextElementSibling.classList.remove('hidden')
            elem.nextElementSibling.classList.add('block')
            hasInvalidElems = true
          }
        })

        // don't update stores and continue until we have the values we need
        if (hasInvalidElems) return

        // update stores
        setStoreObjValue('student', 'id', formData.get('student_id'))
        setStoreObjValue('student', 'name', formData.get('student'))
        setStoreObjValue('assessment', 'centre', formData.get('centre_name'))
        setStoreObjValue('assessment', 'subject', formData.get('subject'))
        setStoreObjValue('assessment', 'paper', formData.get('paper'))

        if (isDebugMode) {
          console.log(localStorage)
        }

        // redirect to next page
        barba.go('/app/accessibility.php')
      })
    }
  })
}

export { initMethodsSetup }
