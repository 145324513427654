import barba from "@barba/core";
import { gsap } from "gsap";
import { initNarrative } from "../components/narrative";
import { delay, paginationControls } from "../components/utils";
import { sFX } from "../components/sfx";

const trial_7_3 = () => {
  delay(500).then(() => {

    // --------------------------------------------------
    // question variables
    // --------------------------------------------------
    let questionIndex = 7;

    // pagination
    paginationControls(questionIndex);

    // narrative animations
    initNarrative();
    // cogs
    let helpers = document.querySelectorAll(".js-helper");
    let cogInput = document.querySelector("#cogInput");
    let cogs = gsap.utils.toArray(".js-cog");
    let cogAntiClockwise = document.querySelector(".js-cog_anticlockwise");
    let cogClockwise = document.querySelector(".js-cog_clockwise");
    let cogReset = document.querySelector(".js-cog_reset");
    let angle = 360 / 8;
    let isAnimating = false;
    // rotator function
    const rotator = (dir) => {
      if (isAnimating) return;
      isAnimating = true;
      helpers.forEach((el) => el.classList.remove("hidden"));
      let tl = gsap.timeline({
        defaults: { duration: 0.4 },
        onComplete: () => {
          isAnimating = false;
        },
      });
      let cog1RotationDir = dir == -1 ? `-=${angle}` : `+=${angle}`;
      let cog2RotationDir = dir == -1 ? `+=${angle}` : `-=${angle}`;
      let cog3RotationDir = dir == -1 ? `-=${angle}` : `+=${angle}`;
      tl.to(cogs[0], { rotation: cog1RotationDir });
      tl.to(cogs[1], { rotation: cog2RotationDir }, "-=0.4");
      tl.to(cogs[2], { rotation: cog3RotationDir }, "-=0.4");
    };
    // cog buttons
    cogAntiClockwise.addEventListener("click", () => {
      sFX("spellbound/sfx_safe.mp3");
      rotator(-1);
    });
    cogClockwise.addEventListener("click", () => {
      sFX("spellbound/sfx_safe.mp3");
      rotator(1);
    });
    cogReset.addEventListener("click", () => {
      sFX("spellbound/sfx_safe.mp3");
      gsap.to(cogs, { duration: 0.4, rotation: 0 });
    });
    // cog input listener
    cogInput.addEventListener("keyup", (e) => {
      if (e.target.value.toUpperCase() == "BETRAYAL") {
        sFX("spellbound/sfx_puzzle_complete.mp3");
        helpers.forEach((el) => el.classList.add("hidden"));
      }
    });
    // slide navigation
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if (prev) {
      prev.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
    if (next) {
      next.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
  });
};

export { trial_7_3 };
