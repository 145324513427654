import { gsap } from 'gsap'
import { setStoreValue, getStoreValue } from '../components/stores'

const tutorial = (module) => {
    // see if we have viewed the tutorial
    if (
        getStoreValue('seenTutorial') === false ||
        getStoreValue('seenTutorial') === null
    ) {
        // get all tutorials
        let tutorials = document.querySelectorAll('.js-tutorial')
        let counter = 0
        let isAnimating = false

        // loop through and only show the one we want
        tutorials.forEach((t) => {
            let theModule = t.getAttribute('data-module')
            if (theModule == module) {
                let tutorialImages = t.querySelectorAll('.js-tutorial > img')
                //let counter = 0;
                // click events
                t.addEventListener('click', (e) => {
                    e.preventDefault()
                    if (isAnimating) return
                    isAnimating = true
                    let cur = counter
                    let next = cur + 1
                    let tl = gsap.timeline({
                        onComplete: () => {
                            counter++
                            isAnimating = false
                        },
                    })
                    if (next == tutorialImages.length) {
                        gsap.to(t, {
                            ease: 'power3.out',
                            duration: 0.4,
                            scale: 0.95,
                            autoAlpha: 0,
                            pointerEvents: 'none',
                            onComplete: () => {
                                setStoreValue('seenTutorial', true)
                                t.remove()
                            },
                        })
                    } else {
                        tl.to(tutorialImages[cur], { opacity: 0 })
                        tl.to(tutorialImages[next], { opacity: 1 })
                    }
                })
                gsap.set(tutorialImages[1], { opacity: 0 })
                gsap.to(t, {
                    ease: 'power3.out',
                    delay: 1,
                    duration: 0.4,
                    scale: 1,
                    autoAlpha: 1,
                    pointerEvents: 'auto',
                })
            }
        })
    }
}

export { tutorial }
