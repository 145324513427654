import { gsap } from "gsap";
import { delay } from "./utils";
import { sFX } from "./sfx";
import { getStoreObjValue } from "./stores";

const encourager = () => {
  let el = document.querySelector(".js-encouragement");

  // if the encourager exists
  if (el) {
    // get the name of the user from localStorage
    let user = getStoreObjValue("student", "name");

    // create an array of encouraging messages
    let messages = [
      `Well done ${user}, keep going!`,
      `Keep going, ${user}!`,
      `You're doing so well ${user}, don't stop now!`,
      `Don't stop now ${user}!`,
    ];

    // set random message
    let h2 = el.querySelector("h2");
    h2.textContent = messages[(Math.random() * messages.length) | 0];

    // set
    let content = el.querySelector(".js-encouragement__inner");
    gsap.set(content, { yPercent: 25 });

    // toggle
    const encouragerToggle = (dir) => {
      if (isDebugMode) {
        console.log(
          dir == 0 ? "Encourager dismissed..." : "Encourager initialised..."
        );
      }
      gsap.to(content, {
        duration: 0.6,
        ease: dir == 0 ? "back.inOut" : "back.out",
        autoAlpha: dir == 0 ? 0 : 1,
        yPercent: dir == 0 ? 25 : 0,
        onComplete: () => {
          if (dir == 0) {
            el.remove();
          }
        },
      });
    };

    // dismiss on click
    let btn = content.querySelector(".js-encouragement__close");
    btn.addEventListener(
      "click",
      (e) => {
        sFX("sfx/sfx-clue.mp3");
        encouragerToggle(0);
      },
      false
    );

    // reveal after 1 sec
    delay(2000).then(() => encouragerToggle(1));

  }
};

export { encourager };
