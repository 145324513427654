import barba from "@barba/core";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { audio } from "../components/audio";
import { sFX } from "../components/sfx";

const escape_q9 = () => {
    delay(500).then(() => {

    // --------------------------------------------------
    // question
    // --------------------------------------------------
    let questionIndex = 9;
    let questionPart = 0;
    let questionPart2 = 1;

    let digits = { value1: "00", value2: "00" };
    const number1 = document.querySelector(".number-1");
    const number2 = document.querySelector(".number-2");

    // --------------------------------------------------
    // methods
    // --------------------------------------------------
    paginationControls(questionIndex);
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();
  
    // --------------------------------------------------
    // modify storage
    // --------------------------------------------------

    // elems
    let textarea = document.querySelector('#working');

    // get localStorage
    let storedValue = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart);
    let storedValue2 = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart2);
   
    if(storedValue) {
        textarea.value = storedValue;
    }

    if (storedValue2 !== "") {
        if (typeof storedValue2 === "object" && storedValue2 !== null) {
          number1.textContent = storedValue2.value1;
          number2.textContent = storedValue2.value2;
        }
      }

    // set localStorage
    const setLocalStorage = (link) => {
        setStoreQuestionValue("assessment", "questions", questionIndex, questionPart, textarea.value);
        setStoreQuestionValue("assessment", "questions", questionIndex, questionPart2, digits);
        barba.go(link);
    }

    // --------------------------------------------------
    // numbers
    // --------------------------------------------------

    const counter = (dir, el) => {
        let value =
          dir == "add"
            ? parseInt(el.textContent) + 1
            : parseInt(el.textContent) - 1;
        if (el.classList.contains("number-1")) {
          if (value < 0) {
            value = "23";
          }
          if (value > -1 && value < 10) {
            value = "0" + value;
          }
          if (value > 23) {
            value = "00";
          }
          digits.value1 = value;
        } else {
          if (value < 0) {
            value = "59";
          }
          if (value > -1 && value < 10) {
            value = "0" + value;
          }
          if (value > 59) {
            value = "00";
          }
          digits.value2 = value;
        }
        el.textContent = value;
      };
  
      let countIncrement = 1;
      let countDecrement = 1;
      let countIncrementItems = [".number-1_increment", ".number-2_increment"];
      let countDecrementItems = [".number-1_decrement", ".number-2_decrement"];
  
      // Play audio when increase/decrease buttons are clicked.
      var timeButtons = document.querySelectorAll(".time-button");
      for (let i = 0; i < timeButtons.length; i++) {
        timeButtons[i].addEventListener("click", () => {
          sFX('escape-the-lab/SFX-Watch-Button.mp3');
        });
      }
  
      countIncrementItems.forEach((el) => {
        let m = `.number-${countIncrement}`;
        document
          .querySelector(el)
          .addEventListener("click", () =>
            counter("add", document.querySelector(m))
          );
        countIncrement++;
      });
  
      countDecrementItems.forEach((el) => {
        let m = `.number-${countDecrement}`;
        document
          .querySelector(el)
          .addEventListener("click", () =>
            counter("minus", document.querySelector(m))
          );
        countDecrement++;
      });

    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    
});
};

export { escape_q9 };
