import barba from "@barba/core";
import { gsap } from "gsap";
import { delay } from "../components/utils";
import { cluesInit } from "../components/clue";
import { bubbles } from "../components/bubbles";

const trial_9_2 = () => {

    delay(500).then(() => {

    bubbles();
    cluesInit("sfx_birth_record.mp3", "spellbound");

    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const saveAndContinue = (link) => { 
        barba.go(link); 
    };
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            saveAndContinue(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            saveAndContinue(e.target.href);
        });
    }
    
});
};

export { trial_9_2 };
