import barba from "@barba/core";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { initNarrative } from "../components/narrative";
import { delay } from "../components/utils";
import { sFX } from "../components/sfx";

gsap.registerPlugin(Draggable, InertiaPlugin);

const trial_2_4 = () => {
  delay(500).then(() => {

    // narrative animations
    initNarrative();

    // drag and drop
    const droppables = gsap.utils.toArray(".box");
    const dropZones = gsap.utils.toArray(".boxDrop");
    const overlapThreshold = "25%";
    const parent = document.querySelector(".js-shuffle");
    const divs = [...parent.querySelectorAll("div")];
    let matchedCount = 2;
    while (divs.length) {
      parent.append(divs.splice(Math.floor(Math.random() * divs.length), 1)[0]);
    }
    droppables.forEach((el) => {
      let insideZone = false;
      let isMatch = false;
      let rect1;
      let rect2;
      gsap.set(el, { rotation: gsap.utils.random(-5, 5, 5, true) });
      Draggable.create(el, {
        onDragStart: function () {
          sFX("spellbound/sfx_paper_piece.mp3");
        },
        onDrag: function () {
          insideZone = false;
          isMatch = false;
          for (var i = 0; i < dropZones.length; i++) {
            if (this.hitTest(dropZones[i], overlapThreshold)) {
              gsap.set(el, { rotation: 0 });
              insideZone = true;
              let dzValue = dropZones[i].dataset.letter;
              let elValue = this.target.dataset.letter;
              if (dzValue == elValue) {
                rect1 = dropZones[i].getBoundingClientRect();
                rect2 = this.target.getBoundingClientRect();
                isMatch = true;
              }
              break;
            }
          }
        },
        onDragEnd: function () {
          sFX("spellbound/sfx_paper_piece.mp3");
          if (!insideZone) {
            gsap.to(this.target, { duration: 0.2, x: 0, y: 0 });
          }
          if (isMatch) {
            el.classList.add("!bg-color_purple_medium");

            matchedCount++;
            let x = "-=" + (rect2.left - rect1.left);
            let y = "-=" + (rect2.top - rect1.top);
            gsap.to(this.target, 0.3, {
              x: x,
              y: y,
              rotation: 0,
              onComplete: () => {
                Draggable.get(this.target).disable();
              },
            });
            if (matchedCount == 7) {
              sFX("spellbound/sfx_puzzle_complete.mp3");
            }
          } else {
            gsap.to(this.target, { duration: 0.3, x: 0, y: 0 });
          }
        },
      });
    });

    // slide navigation
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if (prev) {
      prev.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
    if (next) {
      next.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
  });
};

export { trial_2_4 };
