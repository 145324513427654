import barba from "@barba/core";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { audio } from "../components/audio";
import { sFX } from "../components/sfx";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
gsap.registerPlugin(Draggable, InertiaPlugin);

const escape_q21 = () => {
    delay(500).then(() => {

    // --------------------------------------------------
    // question
    // --------------------------------------------------
    let questionIndex = 21;
    let questionPart = 0;

    // --------------------------------------------------
    // methods
    // --------------------------------------------------
    paginationControls(questionIndex);
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // circle rotator
    // --------------------------------------------------

    const wheels = gsap.utils.toArray(".js-wheels > div");
    const rotationSnap = 360 / 10;
    gsap.set(wheels, { rotation: gsap.utils.random(0, 360, rotationSnap, true) });
    Draggable.create(wheels, {
        type: "rotation",
        inertia: true,
        snap: function (endValue) {
        sFX('escape-the-lab/SFX-Rock-Rotate.mp3');
        //return Math.round(endValue / rotationSnap) * rotationSnap;
        },
    });
  
    // --------------------------------------------------
    // modify storage
    // --------------------------------------------------

    // elems
    let textarea = document.querySelector('#working');

    // get localStorage
    let storedValue = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart);
    if(storedValue) {
        textarea.value = storedValue;
    }

    // set localStorage
    const setLocalStorage = (link) => {
        setStoreQuestionValue("assessment", "questions", questionIndex, questionPart, textarea.value);
        barba.go(link);
    }

    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    
});
};

export { escape_q21 };
