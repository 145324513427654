import { gsap } from 'gsap'

const bubbles = () => {
    let els = gsap.utils.toArray('.js-message')
    if (els.length > 0) {
        gsap.to(els, {
            duration: 0.6,
            scale: 1,
            ease: 'power3',
            stagger: 1,
        })
    }
}

export { bubbles }
