import { getStoreValue, setStoreValue } from '../components/stores'

const myBrowserCheck = () => {
    let hasBeenDismissed = getStoreValue('browserCheck')
    if (
        !hasBeenDismissed ||
        typeof hasBeenDismissed == undefined ||
        hasBeenDismissed == null
    ) {
        // vars
        let bar = document.querySelector('.js-browserCheck')
        let close = bar.querySelector('.js-browserCheck__close')
        let isChromimum = window.chrome
        let winNav = window.navigator
        let vendorName = winNav.vendor
        let isOpera = typeof window.opr !== 'undefined'
        let isEdge = winNav.userAgent.indexOf('Edg') > -1
        // check
        if (
            isChromimum !== null &&
            typeof isChromimum !== 'undefined' &&
            vendorName == 'Google Inc.' &&
            isOpera === false &&
            isEdge === false
        ) {
            if (isDebugMode) {
                console.log('Browser: Google Chrome')
            }
        } else {
            bar.classList.remove('hidden')
        }
        // close
        close.addEventListener('click', () => {
            setStoreValue('browserCheck', true)
            bar.classList.add('hidden')
        })
    }
}

export { myBrowserCheck }
