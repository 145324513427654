import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import { InertiaPlugin } from 'gsap/InertiaPlugin'
import { getHighestZIndex } from '../components/utils'
import { sFX } from '../components/sfx'
gsap.registerPlugin(Draggable, InertiaPlugin)

const calculator = () => {
    const calculator = document.querySelector('#calculator')
    const calculatorClose = document.querySelector('#calculator__close')
    const screen = document.querySelector('#calculator__screen')
    const buttons = document.querySelectorAll('#calculator__controls > button')
    const calculatorOpen = document.querySelector('#calculatorWidgetOpen')

    let calculation = []
    let accumulativeCalculation

    // calculate functions
    function calculate(button) {
        let value = button.textContent
        if(value === '÷'){
            value = '/';
        }
        if(value === '×'){
            value = '*';
        }
        if (value === 'C') {
            calculation = []
            screen.textContent = ''
        } else if (value === '=') {
            screen.textContent = eval(accumulativeCalculation)
        } else {
            calculation.push(value)
            accumulativeCalculation = calculation.join('')
            screen.textContent = accumulativeCalculation
        }
    }

    // buttons
    buttons.forEach((button) =>
        button.addEventListener('click', () => calculate(button))
    )

    // create draggable
    Draggable.create(calculator, {
        bounds: window,
        force3D: true,
        onPress: () => getHighestZIndex(calculator),
    })

    // animation
    const animateCalculator = (dir) => {
        sFX('sfx/sfx-clue.mp3')
        gsap.to(calculator, {
            duration: 0.6,
            ease: dir == 0 ? 'back.inOut' : 'back.out',
            autoAlpha: dir == 0 ? 0 : 1,
            pointerEvents: dir == 0 ? 'none' : 'all',
            scale: dir == 0 ? 0.5 : 1,
        })
    }

    // open overlay
    calculatorOpen.addEventListener('click', () => {
        getHighestZIndex(calculator)
        animateCalculator(1)
    })

    // close overlay
    calculatorClose.addEventListener('click', () => {
        animateCalculator(0)
    })
}

export { calculator }
