import barba from '@barba/core'
import { shake } from '../components/shaker'
import { delay } from '../components/utils'
import { createStore, setStoreValue } from '../components/stores'
import { postData } from '../components/post'

const initMethodsHome = () => {
  delay(100).then(() => {
    // login
    let formElement = document.querySelector('#loginForm')
    let noteElement = document.querySelector('#loginFormNote')
    if (formElement) {
      formElement.addEventListener('submit', (e) => {
        if (isDebugMode) {
          console.log('Attempting login...')
        }
        e.preventDefault()
        let formData = new FormData(formElement)
        let user = formData.get('username')
        let pass = formData.get('password')
        let data = { username: user, password: pass }
        postData(
          'https://reallyneet.wpengine.com/wp-json/really-neet-plugin/v1/login',
          data,
          'POST'
        )
          .then((response) => {
            createStore()
            let data = response.data
            if (
              user == data.user_login &&
              Number.isInteger(parseInt(data.ID))
            ) {
              let theTeacher = { id: data.ID, email: data.user_login }
              setStoreValue('teacher', theTeacher)
              setStoreValue('loggedIn', true)
              barba.go('/app/setup.php')
            }
          })
          .catch((e) => {
            noteElement.classList.remove('opacity-0')
            noteElement.setAttribute('aria-hidden', false)
            shake('#loginForm')
          })
      })
    }
  })
}

export { initMethodsHome }
