import barba from "@barba/core";
import { gsap } from "gsap";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { audio } from "../components/audio";
import { sFX } from "../components/sfx";

const escape_q11 = () => {
    delay(500).then(() => {

    // --------------------------------------------------
    // elements
    // --------------------------------------------------
    let textarea = document.querySelector('#working');
    let code = document.querySelector('#code');

    // --------------------------------------------------
    // question
    // --------------------------------------------------
    let questionIndex = 11;
    let questionPart = 0;
    let questionPart2 = 1;

    // --------------------------------------------------
    // methods
    // --------------------------------------------------
    paginationControls(questionIndex);
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // keypad
    // --------------------------------------------------
    
    // keypad buttons
    gsap.utils.toArray(".js-keys > div").forEach((el) => {
        el.addEventListener("click", function (e) {
            sFX("sfx/sfx_keypad_press.mp3");
            code.value = code.value + el.textContent;
        });
    });

    // form
    let form = document.getElementById("keypad");

    // reset form
    form.addEventListener("reset", function (e) {
        sFX("sfx/sfx_keypad_press.mp3");
        e.preventDefault();
        form.querySelector("#code").value = "";
    });

    // submit form
    form.addEventListener("submit", function (e) {
        sFX("sfx/sfx_keypad_press.mp3");
        e.preventDefault();
        gsap.to("#myClue", {
            autoAlpha: 0,
            duration: 0.4,
            pointerEvents: "none",
            scale: 0.6,
            ease: "power4.out",
        });
    });

  
    // --------------------------------------------------
    // modify storage
    // --------------------------------------------------

    // get localStorage
    let storedValue = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart);
    let storedValue2 = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart2);

    // render front end
    if(storedValue) { textarea.value = storedValue; }
    if(storedValue2) { code.value = storedValue2; }

    // set localStorage
    const setLocalStorage = (link) => {
        setStoreQuestionValue("assessment", "questions", questionIndex, questionPart, textarea.value);
        setStoreQuestionValue("assessment", "questions", questionIndex, questionPart2, code.value);
        barba.go(link);
    }

    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    
});
};

export { escape_q11 };
