import { gsap } from 'gsap'
import imagesLoaded from 'imagesloaded'

// progress
const progressEl = document.querySelector('.js-progress')

// fade in
const fadeIn = (container) => {
    // set default postion for container
    gsap.set(container, { autoAlpha: 0, x: -24 })
    // show preloader
    gsap.to(progressEl, { autoAlpha: 1, pointerEvents: 'auto', duration: 0.3 })
    // imagesLoaded
    const imgLoad = imagesLoaded(container)
    // when all images are loaded
    return imgLoad.on('done', () => {
        return new Promise((resolve) => {
            const timeline = gsap.timeline({
                onComplete() {
                    resolve()
                },
            })
            timeline
                .to(
                    progressEl,
                    { duration: 0.3, autoAlpha: 0, pointerEvents: 'none' },
                    0.4
                )
                .to(container, {
                    duration: 1,
                    autoAlpha: 1,
                    x: 0,
                    ease: 'power3.out',
                })
        })
    })
}

// fade out
const fadeOut = (container) => {
    return new Promise((resolve) => {
        const timeline = gsap.timeline({
            onComplete() {
                resolve()
            },
        })
        timeline
            .to(container, {
                duration: 0.6,
                x: 24,
                autoAlpha: 0,
                ease: 'power3.inOut',
            })
            .fromTo(
                progressEl,
                { autoAlpha: 0, pointerEvents: 'none' },
                { duration: 0.3, autoAlpha: 1, pointerEvents: 'auto' }
            )
    })
}

export { fadeIn, fadeOut }
