import barba from '@barba/core'
import { gsap } from 'gsap'
import { delay, paginationControls } from '../components/utils'
import { cluesInit } from '../components/clue'
import {
    setStoreQuestionValue,
    getStoreQuestionValue,
} from '../components/stores'
import { audio } from '../components/audio'
import { bubbles } from '../components/bubbles'
import { navigationButtonsCallback } from '../components/navigationCallback'
import { dictionary } from '../components/dictionary'

const rotary_12 = () => {
    delay(500).then(() => {
        if (isDebugMode) {
            console.log('Rotary Q12: Loaded')
        }

        // --------------------------------------------------
        // QUESTION
        // --------------------------------------------------
        let questionIndex = 12
        let questionPart = 0
        let questionPart2 = 1

        // --------------------------------------------------
        // METHODS
        // --------------------------------------------------
        paginationControls(questionIndex)
        cluesInit()
        bubbles()
        audio()
        dictionary()

        // --------------------------------------------------
        // STORAGE
        // --------------------------------------------------

        // GET localStorage and render on the frontend
        let storedValue = getStoreQuestionValue(
            'assessment',
            'questions',
            questionIndex,
            questionPart
        )
        let storedValue2 = getStoreQuestionValue(
            'assessment',
            'questions',
            questionIndex,
            questionPart2
        )

        if (storedValue) {
            gsap.utils.toArray('[name=radio_28_1]').forEach((element) => {
                if (element.value == storedValue2) {
                    element.checked = true
                }
            })
        }
        if (storedValue2) {
            gsap.utils.toArray('[name=radio_28_2]').forEach((element) => {
                if (element.value == storedValue) {
                    element.checked = true
                }
            })
        }

        // SET localStorage values for this question
        const setLocalStorage = () => {
            let selectedCheckbox = document.querySelector(
                '[name=radio_28_1]:checked'
            )
            if (selectedCheckbox !== null) {
                setStoreQuestionValue(
                    'assessment',
                    'questions',
                    questionIndex,
                    questionPart,
                    selectedCheckbox.value
                )
            }
            let selectedCheckbox2 = document.querySelector(
                '[name=radio_28_2]:checked'
            )
            if (selectedCheckbox2 !== null) {
                setStoreQuestionValue(
                    'assessment',
                    'questions',
                    questionIndex,
                    questionPart2,
                    selectedCheckbox2.value
                )
            }
        }

        // --------------------------------------------------
        // NAVIGATION
        // --------------------------------------------------
        navigationButtonsCallback(setLocalStorage)
    })
}

export { rotary_12 }
