import barba from "@barba/core";
import { gsap } from "gsap";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { calculator } from "../components/calculator";
import { audio } from "../components/audio";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";
import { navigationButtonsCallback } from "../components/navigationCallback";

const mythias_mission_12 = () => {
  delay(500).then(() => {
    // --------------------------------------------------
    // CHART
    // --------------------------------------------------
    let chartButtons = gsap.utils.toArray("[data-chart]");
    let arrayOfValues = [];
    // manage chart
    const manageChart = (el, value) => {
      // see if it exists in the array
      if (arrayOfValues.includes(value)) {
        let newArr = arrayOfValues.filter((el) => el !== value);
        arrayOfValues = newArr;
      } else {
        arrayOfValues.push(value);
      }
      // show/hide visibility
      el.classList.toggle("bg-[#00b51a]");
    };
    // loop through buttons
    chartButtons.forEach((btn) => {
      let el = btn;
      let value = btn.dataset.chart;
      btn.addEventListener("click", (e) => {
        manageChart(el, value);
      });
    });

    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector("#working");
    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 12;
    let questionPart = 0;
    let questionPart2 = 1;
    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex);
    calculator();
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------

    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart
    );
    let storedValue2 = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart2
    );
    if (storedValue) {
      textarea.value = storedValue;
    }
    if (storedValue2) {
      arrayOfValues = storedValue2;
      chartButtons.forEach((btn) => {
        if (arrayOfValues.includes(btn.dataset.chart)) {
          btn.classList.add("bg-[#00b51a]");
        }
      });
    }

    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart,
        textarea.value
      );
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart2,
        arrayOfValues
      );
    };

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage);
  });
};
export { mythias_mission_12 };
