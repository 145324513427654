import barba from "@barba/core";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { initNarrative } from "../components/narrative";
import { delay } from "../components/utils";
import { sFX } from "../components/sfx";
import { navigationButtonsBasic } from "../components/navigation";

gsap.registerPlugin(Draggable, InertiaPlugin);

const trial_4_3 = () => {
  delay(500).then(() => {
    // narrative animations
    initNarrative();

    // get vars
    const wheels = gsap.utils.toArray(".js-wheels > .js-wheel");
    const rotationSnap = 10;
    let rotationTracker = {
      // "ring-1": 360,
      "ring-2": 360,
      "ring-3": 360,
      "ring-4": 360,
      "ring-5": 360,
    };

    // set a random rotation
    gsap.set(wheels, {
      rotation: gsap.utils.random(rotationSnap, 270, rotationSnap, true),
    });

    // draggable
    Draggable.create(wheels, {
      type: "rotation",
      inertia: true,
      snap: function (endValue) {
        let angle = Math.round(endValue / rotationSnap) * rotationSnap;
        rotationTracker[this.target.id] = angle / 360;
        return angle;
      },
      onDragStart: function () {
        sFX("spellbound/sfx_magic.mp3");
      },
      onDragEnd: function () {
        let alignedCount = 0;
        for (const property in rotationTracker) {
          if (
            rotationTracker[property] == 0 ||
            rotationTracker[property] == -1 ||
            rotationTracker[property] == 1
          ) {
            alignedCount++;
          }
        }
        if (alignedCount == 4) {
          if (isDebugMode) {
            console.log(rotationTracker);
          }
          document.querySelector(".js-wheels").classList.add("animate-pulse");
          sFX("spellbound/sfx_puzzle_complete.mp3");
        }
      },
    });
    // navigation
    navigationButtonsBasic();
  });
};

export { trial_4_3 };
