import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { sFX } from "./sfx";
import { getHighestZIndex } from "./utils";
gsap.registerPlugin(Draggable, InertiaPlugin);

const cluesInit = () => {



  // clue window
  const revealer = (el, dir) => {

    // Get the element to load
    let elem = document.getElementById(el.dataset.clue);

    // if there is an audio attached, play sound effect
    // let sound = el.dataset.clueaudio;
    // if (dir == 1 && sound && typeof sound !== "undefined") {
    //   sFX(sound);
    // }

    // play click sound
    sFX("sfx/sfx_open_diary.mp3");

    // create  draggable instance of this element
    Draggable.create(elem, {
      bounds: window,
      force3D: true,
     // inertia: true,
      onPress: () => getHighestZIndex(elem),
    });

    getHighestZIndex(elem);

    // animate in
    let tl = gsap.timeline({
      onComplete: () => {
        if (dir == 0) {
          // reset the element position
          gsap.set(elem, {
            x: gsap.utils.random(-24, 24, 6),
            y: gsap.utils.random(-24, 24, 6),
          });
        }
      },
    });
    tl.to(
      elem,
      {
        autoAlpha: dir == 0 ? 0 : 1,
        duration: 0.6,
        pointerEvents: dir == 0 ? "none" : "all",
        scale: dir == 0 ? 0.75 : 1,
        ease: dir == 0 ? "back.inOut" : "back.out",
      },
      "-=0.2"
    );
    return tl;
  };

  // clue buttons
  gsap.utils.toArray(".clue-open").forEach((el) => {
    let isPlaying = false;
    let text = el.querySelector("span");
    let tl = gsap.timeline({ paused: true });
    tl.to(el, { duration: 0.6, width: "auto", ease: "power4.out" });
    tl.to(
      text,
      {
        duration: 0.6,
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        ease: "power4.out",
      },
      "-=0.6"
    );
    // open clues
    el.addEventListener("mouseenter", () => {
      tl.play();
    });
    el.addEventListener("mouseleave", () => {
      tl.reverse();
    });
    el.addEventListener("click", () => revealer(el, 1), false);
  });

  // close clues
  gsap.utils.toArray(".clue-close").forEach((el) => {
    el.addEventListener("click", () => revealer(el, 0), false);
  });
};

export { cluesInit };
