import barba from '@barba/core'
import { gsap } from 'gsap'
import { delay } from '../components/utils'
import { sFX } from '../components/sfx'

const initVideo = () => {
    delay(500).then(() => {
        const video = document.querySelector('video')
        if (video) {
            // next button
            let next = document.querySelector('.js-next')
            if (next) {
                next.addEventListener('click', (e) => {
                    e.preventDefault()
                    sFX('sfx/sfx-clue.mp3')
                    barba.go(e.target.href)
                })
            }
            // play video button
            const videoBtn = document.querySelector('#videoBtn')
            videoBtn.addEventListener('click', () => {
                sFX('sfx/sfx-clue.mp3')
                video.play()
                videoBtn.remove()
            })
            gsap.to(next, {
                delay: 2,
                duration: 1,
                autoAlpha: 1,
                onComplete: () => {
                    next.disabled = false
                },
            })
        }
    })
}

export { initVideo }
