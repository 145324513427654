import barba from "@barba/core";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
import { delay, paginationControls } from "../components/utils";
import { initNarrative } from "../components/narrative";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { calculator } from "../components/calculator";
import { audio } from "../components/audio";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";
import { navigationButtonsCallback } from "../components/navigationCallback";

const mythias_mission_7 = () => {

  if(isDebugMode){
    console.log('Mythias Mission 7 initialised...');
  }

  delay(500).then(() => {
    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector("#working");
    let newObj = {};

    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 7;
    let questionPart = 0;
    let questionPart2 = 1;

    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex);
    calculator();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------

    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart
    );
    let storedValue2 = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart2
    );
    if (storedValue) {
      textarea.value = storedValue;
    }
    if (storedValue2) {
      newObj = storedValue2;
    }

    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart,
        textarea.value
      );
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart2,
        newObj
      );
    };

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage);
    // --------------------------------------------------
    // TRIANGLE
    // --------------------------------------------------

    // elems
    let triangle = document.querySelector("#triangle");
    let handleDef = document.querySelector("defs .handle");
    let handleLayer = document.querySelector("#handle-layer");
    let points = [];
    let numPoints = triangle.points.numberOfItems;

    // render points
    if (!Object.entries(storedValue2).length > 0) {
      for (let i = 0; i < numPoints; i++) {
        let point = triangle.points.getItem(i);
        points[i] = { x: point.x, y: point.y };
        createHandle(point);
      }
    } else {
      // build polygon shape
      let polygon = [];
      for (const [key, value] of Object.entries(newObj)) {
        polygon.push(`${value.x}, ${value.y}`);
      }
      triangle.setAttribute("points", polygon);
      // create the handles
      for (const [key, value] of Object.entries(newObj)) {
        let point = triangle.points.getItem(key);
        points[key] = { x: point.x, y: point.y };
        createHandle(point);
      }
    }

    // create draggable
    function createHandle(point) {
      let handle = createClone(handleDef, handleLayer, point);
      let draggable = new Draggable(handle, {
        bounds: "#svg",
        onDrag: moveAction,
        onDragEnd: newPointSequence,
      });
      function moveAction() {
        point.x = this.x;
        point.y = this.y;
      }
      function newPointSequence() {
        newObj = {
          0: { x: triangle.points[0].x, y: triangle.points[0].y },
          1: { x: triangle.points[1].x, y: triangle.points[1].y },
          2: { x: triangle.points[2].x, y: triangle.points[2].y },
        };
      }
    }

    // create cloned handle
    function createClone(node, parent, point) {
      let element = node.cloneNode(true);
      parent.appendChild(element);
      gsap.set(element, { x: point.x, y: point.y });
      return element;
    }
  });
};
export { mythias_mission_7 };
