import barba from "@barba/core";
import { gsap } from "gsap";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { calculator } from "../components/calculator";
import { audio } from "../components/audio";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";
import { navigationButtonsCallback } from "../components/navigationCallback";

const mythias_mission_17 = () => {
  delay(500).then(() => {
    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector("#working");
    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 17;
    let questionPart = 0;
    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex);
    calculator();
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------

    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart
    );
    if (storedValue) {
      textarea.value = storedValue;
    }

    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart,
        textarea.value
      );
    };

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage);
  });
};
export { mythias_mission_17 };
