import { gsap } from 'gsap'
import barba from '@barba/core'

const initLogo = () => {
  if (isDebugMode) {
    console.log('Animating Logo...')
  }
  let tl = gsap.timeline({
    onComplete: () => {
      barba.go(`/app/login.php`)
    },
  })
  tl.to('.js-logo', {
    scale: 1,
    opacity: 1,
    ease: 'elastic.out',
    duration: 1.6,
  })
  tl.to('.js-logo', {
    delay: 2,
    scale: 0.8,
    opacity: 0,
    ease: 'back.inOut',
    duration: 0.6,
  })
}

export { initLogo }
