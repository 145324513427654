import barba from "@barba/core";
import { gsap } from "gsap";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { calculator } from "../components/calculator";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { audio } from "../components/audio";
import { navigationButtonsCallback } from "../components/navigationCallback";

const mythias_mission_1 = () => {
  delay(500).then(() => {

    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector("#working");

    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 1;
    let questionPart = 0;
    let questionPart2 = 1;

    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex);
    calculator();
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------

    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue( "assessment", "questions", questionIndex, questionPart );
    let storedValue2 = getStoreQuestionValue( "assessment", "questions", questionIndex, questionPart2 );
    if (storedValue) {
      textarea.value = storedValue;
    }
    if(storedValue2) { 
        gsap.utils.toArray('[name=mission-1-radio]').forEach(element => {
            if(element.value == storedValue2){ element.checked = true; }
        });
    }

    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue( "assessment", "questions", questionIndex, questionPart, textarea.value );
      let selectedCheckbox = document.querySelector('[name=mission-1-radio]:checked');
      if(selectedCheckbox !== null){ setStoreQuestionValue("assessment", "questions", questionIndex, questionPart2, selectedCheckbox.value); }
    };

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage);
    // const next = document.querySelector(".js-next");
    // const prev = document.querySelector(".js-prev");
    // if (prev) {
    //   prev.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     setLocalStorage(e.target.href);
    //   });
    // }
    // if (next) {
    //   next.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     setLocalStorage(e.target.href);
    //   });
    // }
  });
};

export { mythias_mission_1 };
