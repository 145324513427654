import { gsap } from 'gsap'

const customSelect = () => {
    let customMenus = gsap.utils.toArray('.js-select')

    if (customMenus.length > 0) {
        // animate
        const animate = (el, dir) => {
            return gsap.to(el, {
                ease: 'power3.out',
                duration: 0.4,
                autoAlpha: dir == 1 ? 1 : 0,
                x: dir == 1 ? 0 : 10,
            })
        }

        // selects
        customMenus.forEach((el) => {
            // variables
            let input = el.querySelector('input')
            let clickables = gsap.utils.toArray('.js-select__option', el)
            let menu = el.querySelector('.js-select__menu')
            let selectedContent = el.querySelector('.js-select__current')
            let trigger = el.querySelector('.js-select__trigger')

            // trigger
            trigger.addEventListener('click', (e) => {
                menu.classList.toggle('js-select--open')
                if (menu.classList.contains('js-select--open')) {
                    animate(menu, 1)
                } else {
                    animate(menu, 0)
                }
            })

            // on click links
            clickables.forEach((el) => {
                el.addEventListener('click', (e) => {
                    input.value = el.dataset.value
                    selectedContent.innerHTML = el.querySelector(
                        '.js-select__content'
                    ).innerHTML
                    menu.classList.toggle('js-select--open')
                    animate(menu, 0)
                })
            })
        })

        // on click outside
        // const onClickOutside = (e) => {
        //   if (!e.target.className.includes("js-select")) {
        //     customMenus.forEach((el) => {
        //       let theMenu = el.querySelector('.js-select__menu');
        //       if (theMenu.classList.contains("js-select--open")) {
        //         theMenu.classList.remove("js-select--open");
        //         animate(theMenu, 0);
        //       }
        //     });
        //   }
        // };
        // window.addEventListener("click", onClickOutside);
    }
}

export { customSelect }
