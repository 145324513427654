import { gsap } from 'gsap'
import { stripNaughtyWords } from './utils'

const wysiwyg = () => {
    // check for naughty words and strip
    const textarea = document.getElementById('working')
    if (textarea) {
        stripNaughtyWords(textarea)
    }
    // on special character button click
    let buttons = gsap.utils.toArray('.js-math-buttons > button')
    buttons.forEach((btn) => {
        const addToField = (e) => {
            let specialChar = e.target.textContent
            const insertStartPoint = textarea.selectionStart
            const insertEndPoint = textarea.selectionEnd
            let value = textarea.value
            value = value.slice(0, insertStartPoint) + specialChar + value.slice(insertEndPoint)
            textarea.value = value
            textarea.focus()
        }
        btn.addEventListener('click', addToField)
    });
}

export { wysiwyg }