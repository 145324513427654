import { gsap } from 'gsap'
import { getStoreObjValue } from './stores'

const audio = () => {
    let audioEnabled = getStoreObjValue('accessibility', 'audio')
    if (audioEnabled) {
        if (isDebugMode) {
            console.log('Audio playback initialised...')
        }
        gsap.utils.toArray('[data-audio]').forEach((el) => {
            let audioEl = document.getElementById(el.dataset.audio)
            if (audioEl) {
                let play = el.querySelector('[data-audio-play]')
                let pause = el.querySelector('[data-audio-pause]')
                let text = el.querySelector('[data-audio-text]')
                let isPlaying = false
                audioEl.addEventListener('ended', (event) => {
                    text.textContent = 'Play'
                    pause.classList.add('hidden')
                    play.classList.remove('hidden')
                })
                el.addEventListener('click', (event) => {
                    if (isPlaying) {
                        audioEl.pause()
                        text.textContent = 'Play'
                        isPlaying = false
                    } else {
                        audioEl.play()
                        text.textContent = 'Pause'
                        isPlaying = true
                    }
                    pause.classList.toggle('hidden')
                    play.classList.toggle('hidden')
                })
            }
            document.querySelector('[data-audio]').classList.remove('hidden')
        })
    }
}

export { audio }
