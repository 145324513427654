import barba from "@barba/core";
import {
  delay,
  stripNaughtyWords,
  paginationControls,
} from "../components/utils";
import { cluesInit } from "../components/clue";
import { bubbles } from "../components/bubbles";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";
import { tutorial } from "../components/tutorial";

const trial_1_3 = () => {
  delay(500).then(() => {
    // question parts
    let questionIndex = 1;
    let questionPart = 1;

    // pagination
    paginationControls(questionIndex);

    // show tutorial
    tutorial("spellbound");

    // sanitise text area
    const textarea = document.getElementById("working");
    stripNaughtyWords(textarea);

    // clues
    cluesInit("sfx_open_diary.mp3", "spellbound"); // passing sound for now

    // bubbles
    bubbles();

    // get storage
    let storedValue = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart
    );
    if (storedValue) {
      textarea.value = storedValue;
    }

    // set storage
    const setLocalStorage = (link) => {
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart,
        textarea.value
      );
      barba.go(link);
    };

    // buttons
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if (prev) {
      prev.addEventListener("click", (e) => {
        e.preventDefault();
        setLocalStorage(e.target.href);
      });
    }
    if (next) {
      next.addEventListener("click", (e) => {
        e.preventDefault();
        setLocalStorage(e.target.href);
      });
    }
  });
};

export { trial_1_3 };
