import barba from "@barba/core";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { audio } from "../components/audio";
import { sFX } from "../components/sfx";

const escape_q16 = () => {
    delay(500).then(() => {

    // --------------------------------------------------
    // question
    // --------------------------------------------------
    let questionIndex = 16;
    let questionPart = 0;

    // --------------------------------------------------
    // methods
    // --------------------------------------------------
    paginationControls(questionIndex);
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();
  
    // --------------------------------------------------
    // modify storage
    // --------------------------------------------------

    // elems
    let textarea = document.querySelector('#working');

    // get localStorage
    let storedValue = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart);
    if(storedValue) {
        textarea.value = storedValue;
    }

    // set localStorage
    const setLocalStorage = (link) => {
        setStoreQuestionValue("assessment", "questions", questionIndex, questionPart, textarea.value);
        barba.go(link);
    }

    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            setLocalStorage(e.target.href);
        });
    }
    
});
};

export { escape_q16 };
