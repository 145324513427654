import barba from '@barba/core'
import { initNarrative } from '../components/narrative'
import { initConfetti } from '../components/confetti'
import {
    setUserFrontEnd,
    disableBackButton,
    delay,
    getCurrentDateTime,
} from '../components/utils'
import { postData } from '../components/post'
import {
    getStoreValue,
    getStoreObjValue,
    setStoreObjValue,
    resetStore,
} from '../components/stores'

const complete_assessment = (api, survey) => {
    const apiURL = api
    const surveyURL = survey

    delay(500).then(() => {
        // elements
        const survey = document.querySelector('.js-survey')
        const next = document.querySelector('.js-next')
        const prev = document.querySelector('.js-back')
        const title = document.querySelector('.js-text')

        if (isDebugMode) {
            console.log(`ApiURL: ${apiURL}, SurveyURL : ${surveyURL}`)
        }

        // methods
        setUserFrontEnd()
        initConfetti()
        initNarrative()

        // set ended timestamp
        if (getStoreObjValue('assessment', 'ended') == '') {
            let now = getCurrentDateTime()
            setStoreObjValue('assessment', 'ended', now)
        }

        if (isDebugMode) {
            console.log(getStoreObjValue('assessment', 'ended'))
        }

        // create post object for api
        let data = {
            teacher: getStoreValue('teacher'),
            student: getStoreValue('student'),
            assessment: getStoreValue('assessment'),
        }

        // make sure we have the right data
        if (isDebugMode) {
            console.log(JSON.stringify(data))
        }

        // submission
        const submitResults = () => {
            postData(apiURL, data)
                .then((response) => {
                    if (isDebugMode) {
                        console.log(`Result: ${JSON.stringify(response)}`)
                    }
                    // remove buttons, disable history api, and ask to submit survey
                    survey.classList.remove('hidden')
                    next.classList.add('hidden')
                    prev.classList.add('hidden')
                    title.textContent =
                        "Please leave us some feedback. It won't take long we promise!"
                    disableBackButton()
                    resetStore()
                })
                .catch((e) => {
                    alert(`Error: ${e.message}`)
                })
        }

        // navigation
        if (prev) {
            prev.addEventListener('click', (e) => {
                e.preventDefault()
                barba.go(e.target.href)
            })
        }
        if (survey) {
            survey.addEventListener('click', (e) => {
                e.preventDefault()
                window.open(surveyURL, '_blank')
            })
        }
        if (next) {
            next.addEventListener('click', (e) => {
                e.preventDefault()
                submitResults()
            })
        }
    })
}

export { complete_assessment }
