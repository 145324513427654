import barba from '@barba/core'
import { delay } from '../components/utils'
import {
  setStoreObjValue,
  getStoreObjValue,
  getStoreValue,
} from '../components/stores'

const initMethodsAccessibility = () => {
  delay(100).then(() => {
    // get values
    // document.querySelector("#accessibility_dyslexic").checked = getStoreObjValue("accessibility", "dyslexic");
    // document.querySelector("#accessibility_audio").checked = getStoreObjValue("accessibility", "audio");
    // document.querySelector("#accessibility_subtitles").checked = getStoreObjValue("accessibility", "captions");
    // document.querySelector("#accessibility_font_size").value = getStoreObjValue("accessibility", "fontSize");
    // document.querySelector("#accessibility_page_color").value = getStoreObjValue("accessibility", "pageColor");
    // let mySelectedIndex = getStoreObjValue("accessibility", "pageColor");
    // document.querySelectorAll("[name=accessibility_page_color]").forEach((el) => {
    //     if (el.value == mySelectedIndex) {
    //       el.checked = mySelectedIndex;
    //       return;
    //     }
    // });
    if (isDebugMode) {
      console.log('Get accessibility data from localStorage...')
    }
    // update the stores
    const updateStores = () => {
      let formElement = document.querySelector('#accessibilityForm')
      let formData = new FormData(formElement)
      setStoreObjValue(
        'accessibility',
        'captions',
        formData.get('accessibility_subtitles')
      )
      setStoreObjValue(
        'accessibility',
        'audio',
        formData.get('accessibility_audio')
      )
      setStoreObjValue(
        'accessibility',
        'dyslexic',
        formData.get('accessibility_dyslexic')
      )
      setStoreObjValue(
        'accessibility',
        'fontSize',
        formData.get('accessibility_font_size')
      )
      setStoreObjValue(
        'accessibility',
        'pageColor',
        formData.get('accessibility_page_color')
      )
      if (isDebugMode) {
        console.log(getStoreValue('accessibility'))
      }
    }
    // buttons
    document.querySelector('.js-prev').addEventListener('click', () => {
      updateStores()
      barba.go('/app/setup.php')
    })

    document.querySelector('.js-next').addEventListener('click', () => {
      updateStores()
      barba.go('/app/start.php')
    })
  })
}

export { initMethodsAccessibility }
