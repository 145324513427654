import { delay, paginationControls } from '../components/utils'
import { cluesInit } from '../components/clue'
import {
    setStoreQuestionValue,
    getStoreQuestionValue,
} from '../components/stores'
import { audio } from '../components/audio'
import { bubbles } from '../components/bubbles'
import { navigationButtonsCallback } from '../components/navigationCallback'
import { dictionary } from '../components/dictionary'

const rotary_4 = () => {
    delay(500).then(() => {
        if (isDebugMode) {
            console.log('Rotary Q4: Loaded')
        }

        // --------------------------------------------------
        // ELEMENTS
        // --------------------------------------------------
        let container = document.getElementById('rotary_slide_13')
        let checkBoxes = container.querySelectorAll('input[type=checkbox]')

        // --------------------------------------------------
        // QUESTION
        // --------------------------------------------------
        let questionIndex = 4
        let questionPart = 0

        // --------------------------------------------------
        // METHODS
        // --------------------------------------------------
        paginationControls(questionIndex)
        cluesInit()
        bubbles()
        audio()
        dictionary()

        // --------------------------------------------------
        // STORAGE
        // --------------------------------------------------

        // GET localStorage and render on the frontend
        let storedValue = getStoreQuestionValue(
            'assessment',
            'questions',
            questionIndex,
            questionPart
        )
        if (storedValue) {
            checkBoxes.forEach((element) => {
                if (storedValue.includes(element.value)) {
                    element.checked = true
                }
            })
        }

        // SET localStorage values for this question
        const setLocalStorage = () => {
            let arr = []
            checkBoxes.forEach((el) => {
                if (el.checked) {
                    arr.push(el.value)
                }
            })
            setStoreQuestionValue(
                'assessment',
                'questions',
                questionIndex,
                questionPart,
                arr
            )
        }

        // --------------------------------------------------
        // NAVIGATION
        // --------------------------------------------------
        navigationButtonsCallback(setLocalStorage)
    })
}

export { rotary_4 }
