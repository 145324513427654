import barba from '@barba/core'
import { getStoreValue } from './stores'

const notLoggedIn = (namespace) => {
    // allow the login namespaces
    if (namespace == 'home' || namespace == 'login') return
    // check logged in status in localStorage
    let status = getStoreValue('loggedIn')
    // check to see if user is logged in, if not, redirect them to login
    if (typeof status === 'undefined' || status === null || status === false) {
        if (isDebugMode) {
            console.log('Not logged in.')
        }
        barba.go('/app/index.php')
    }
}

export { notLoggedIn }
