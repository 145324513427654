import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(SplitText)

const initNarrative = () => {
    // checks to see if we have narrative objects to animate
    let elems = gsap.utils.toArray('.js-words')

    // if we have elements to animate
    if (elems.length > 0) {
        // debug mode
        if (isDebugMode) {
            console.log('Narrative initialised...')
        }
        // kill any existing tweens of elements
        gsap.killTweensOf('.js-words')
        // create a new SplitText instance
        let split = new SplitText(elems, { type: 'words' })
        // animate the narrative and the reveal navigation button on completeion
        let tl = gsap.timeline({
            onComplete: () => {
                // convert text back to sentence
                split.revert()
            },
        })
        // animate text sequentially
        tl.set(elems, { autoAlpha: 1, perspective: 800 })
        tl.from(split.words, { duration: 1, autoAlpha: 0, stagger: 0.1 })
        let next = document.querySelector('.js-next')
        if (next) {
            tl.to(next, { duration: 0.4, autoAlpha: 1, pointerEvents: 'auto' })
        }
    }
}

export { initNarrative }
