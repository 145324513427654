import barba from "@barba/core";
import { gsap } from "gsap";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { bubbles } from "../components/bubbles";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";

const trial_9_3 = () => {
  delay(500).then(() => {

  // --------------------------------------------------
  // question variables
  // --------------------------------------------------
  let questionIndex = 9;
  let questionPart = 1;
  let questionPart2 = 2;

         // pagination
         paginationControls(questionIndex);

  // --------------------------------------------------
  // Functions
  // --------------------------------------------------
  cluesInit("sfx_birth_record.mp3", "spellbound");
  bubbles();

  // --------------------------------------------------
  // modify storage
  // --------------------------------------------------

  // get localStorage
  let storedValue = getStoreQuestionValue(
    "assessment",
    "questions",
    questionIndex,
    questionPart
  );
  let storedValueB = getStoreQuestionValue(
    "assessment",
    "questions",
    questionIndex,
    questionPart2
  );
  if (storedValue) {
    gsap.utils.toArray("[name=radio_9_1]").forEach((element) => {
      if (element.value == storedValue) {
        element.checked = true;
      }
    });
  }
  if (storedValueB) {
    gsap.utils.toArray("[name=radio_9_2]").forEach((element) => {
      if (element.value == storedValueB) {
        element.checked = true;
      }
    });
  }

  // set localStorage
  const setLocalStorage = () => {
    let selectedCheckbox = document.querySelector("[name=radio_9_1]:checked");
    let selectedCheckboxB = document.querySelector("[name=radio_9_2]:checked");
    if (selectedCheckbox && selectedCheckboxB) {
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart,
        selectedCheckbox.value
      );
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart2,
        selectedCheckboxB.value
      );
    }
  };

  // --------------------------------------------------
  // slide navigation
  // --------------------------------------------------
  const saveAndContinue = (link) => {
    setLocalStorage();
    barba.go(link);
  };
  const next = document.querySelector(".js-next");
  const prev = document.querySelector(".js-prev");
  if (prev) {
    prev.addEventListener("click", (e) => {
      e.preventDefault();
      saveAndContinue(e.target.href);
    });
  }
  if (next) {
    next.addEventListener("click", (e) => {
      e.preventDefault();
      saveAndContinue(e.target.href);
    });
  }
});
};

export { trial_9_3 };
