import barba from "@barba/core";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { calculator } from "../components/calculator";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { audio } from "../components/audio";
import { navigationButtonsCallback } from "../components/navigationCallback";

const mythias_mission_16 = () => {
  delay(500).then(() => {
    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector("#working");
    let answer1 = document.querySelector("#mission-16-value-2");
    let answer2 = document.querySelector("#mission-16-value-4");
    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 16;
    let questionPart = 0;
    let questionPart2 = 1;
    let questionPart3 = 2;
    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex);
    calculator();
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------

    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart);
    let storedValue2 = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart2);
    let storedValue3 = getStoreQuestionValue("assessment", "questions", questionIndex, questionPart3);
    if (storedValue) { textarea.value = storedValue; }
    if (storedValue2) { answer1.value = storedValue2; }
    if (storedValue3) { answer2.value = storedValue3; }

    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue("assessment", "questions", questionIndex, questionPart, textarea.value);
      setStoreQuestionValue("assessment", "questions", questionIndex, questionPart2, answer1.value);
      setStoreQuestionValue("assessment", "questions", questionIndex, questionPart3, answer2.value);
    };

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage);
  });
};
export { mythias_mission_16 };
