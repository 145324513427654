import barba from '@barba/core'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import { delay, paginationControls } from '../components/utils'
import { cluesInit } from '../components/clue'
import { initNarrative } from '../components/narrative'
import { wysiwyg } from '../components/wysiwyg'
import { workbook } from '../components/workbook'
import { calculator } from '../components/calculator'
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from '../components/stores'
import { audio } from '../components/audio'
import { navigationButtonsCallback } from '../components/navigationCallback'

const mythias_mission_11 = () => {
  delay(500).then(() => {
    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector('#working')
    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 11
    let questionPart = 0
    let questionPart2 = 1
    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex)
    calculator()
    cluesInit()
    initNarrative()
    wysiwyg()
    workbook()
    audio()
    // --------------------------------------------------
    // Symmetry
    // --------------------------------------------------
    const isBetween = (value, low, high) => value >= low && value <= high
    const droppables = gsap.utils.toArray('.rotate-line')
    gsap.to(droppables, { scale:1, ease:"back.out", duration:0.4, autoAlpha: 1, stagger: 0.02 })
    const dropZone = gsap.utils.toArray('.hexagon')
    let lines = {
      line0: { x: 0, y: 0 },
      line1: { x: 0, y: 0 },
      line2: { x: 0, y: 0 },
      line3: { x: 0, y: 0 },
      line4: { x: 0, y: 0 },
      line5: { x: 0, y: 0 },
      line6: { x: 0, y: 0 },
      line7: { x: 0, y: 0 },
    }
    droppables.forEach((el) => {
      el.dropped = false
      Draggable.create(el, {
        bounds: '#missionCol',
        onDragStart: function () {
          el.querySelector('div').classList.add('!bg-red-600')
        },
        onDragEnd: function (e) {
          el.querySelector('div').classList.remove('!bg-red-600')
          // get position of line
          if (isDebugMode) {
            console.log(this.target.id, this.x, this.y)
          }
          // correct positions
          let correctCoords = {
            line0: {
              x: -93.3916,
              y: -340.0062,
              correctPosition: 0,
            },
            line1: {
              x: -13.489,
              y: -339.538,
              correctPosition: 0,
            },
            line2: {
              x: 104.8004,
              y: -339.821,
              correctPosition: 0,
            },
            line3: {
              x: 33.3151,
              y: -343.8247,
              correctPosition: 0,
            },
            line4: {
              x: -96.5059,
              y: -344.9714,
              correctPosition: 0,
            },
            line5: {
              x: 14.5751,
              y: -344.8724,
              correctPosition: 0,
            },
            line6: {
              x: 0,
              y: 04,
              correctPosition: 0,
            },
            line7: {
              x: 0,
              y: 0,
              correctPosition: 0,
            },
          }
          // check thresholds and positional values
          let threshold = 6
          let lowestX = correctCoords[this.target.id].x - threshold
          let highestX = correctCoords[this.target.id].x + threshold
          let lowestY = correctCoords[this.target.id].y - threshold
          let highestY = correctCoords[this.target.id].y + threshold
          let xPosCheck = isBetween(this.x, lowestX, highestX)
          let yPosCheck = isBetween(this.y, lowestY, highestY)
          // update the store
          lines[this.target.id].x = this.x
          lines[this.target.id].y = this.y
          // if all good increment
          if (this.hitTest(dropZone, '95%') && xPosCheck && yPosCheck) {
            // set as complete
            if(this.target.id !== 'line6' && this.target.id !== 'line7'){
              lines[this.target.id].correctPosition = 1
            }
            // see how many we have right
            if (isDebugMode) {
              console.log(
                Object.keys(lines).filter(
                  (key) => lines[key].correctPosition === 1
                )
              )
            }
          } else {
            lines[this.target.id].correctPosition = 0
          }
        },
      })
    })
    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------
    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue(
      'assessment',
      'questions',
      questionIndex,
      questionPart
    )
    if (storedValue) {
      textarea.value = storedValue
    }
    let storedValue2 = getStoreQuestionValue(
      'assessment',
      'questions',
      questionIndex,
      questionPart2
    )
    if (storedValue2) {
      lines = storedValue2
      if (isDebugMode) {
        console.log(lines)
      }
      // loop over and set saved positions
      droppables.forEach((el, index) => {
        gsap.set(el, { x: lines[`line${index}`].x, y: lines[`line${index}`].y })
      })
    }
    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue(
        'assessment',
        'questions',
        questionIndex,
        questionPart,
        textarea.value
      )
      setStoreQuestionValue(
        'assessment',
        'questions',
        questionIndex,
        questionPart2,
        lines
      )
    }

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage)
  })
}
export { mythias_mission_11 }
