import Swiper from 'swiper/swiper-bundle.esm.js'
import { setStoreObjValue, setStoreValue } from './stores'
import { sFX } from '../components/sfx'

const carousel = () => {
    // initialise swiper.js carousel
    const swiper = new Swiper('.swiper-container', {
        effect: 'coverflow',
        allowTouchMove: false,
        centeredSlides: true,
        slidesPerView: 'auto',
        initialSlide: 0,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 10,
            initialSlide: 3,
            slideShadows: true,
        },
        pagination: false,
        navigation: {
            nextEl: '.swiper-btn-next',
            prevEl: '.swiper-btn-prev',
        },
    })
    // on change of slide set the module
    swiper.on('slideChange', function (data) {
        sFX('sfx/sfx-clue.mp3')
        let module =
            document.querySelectorAll('.swiper-slide')[data.realIndex].dataset
                .module
        let url =
            document.querySelectorAll('.swiper-slide')[data.realIndex].dataset
                .url
        setStoreValue('module', url)
        setStoreObjValue('assessment', 'module', module)
    })
    // set the default module as the first in the list
    setStoreValue(
        'module',
        document.querySelectorAll('.swiper-slide')[0].dataset.url
    )
    setStoreObjValue(
        'assessment',
        'module',
        document.querySelectorAll('.swiper-slide')[0].dataset.module
    )
}

export { carousel }
