async function postData(url = '', data = {}, method = 'POST') {
    const response = await fetch(url, {
        method: method,
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })
    if (!response.status || response.status > 200) {
        throw new CustomException('403: Incorrect Login')
    }
    return response.json()
}

const CustomException = (message) => {
    const error = new Error(message)
    if (isDebugMode) {
        console.log(message)
    }
}

export { postData }
