import { gsap } from 'gsap'
import { sFX } from './sfx'

const workbook = () => {
  let workbook = document.querySelector('.workbook')
  let open = document.querySelector('.workbook-open')
  let close = document.querySelector('.workbook-close')

  const toggle = () => {
    sFX('sfx/sfx-workbook.mp3')
    let x = workbook.classList.contains('workbook--opened') ? 0 : 100
    let duration = workbook.classList.contains('workbook--opened') ? 0.4 : 0.6
    let tl = gsap.timeline({
      onComplete: () => {
        workbook.classList.toggle('workbook--opened')
      },
    })
    tl.to(workbook, {
      duration: duration,
      xPercent: x,
      ease: 'power3.out',
    })
  }

  if (open) {
    open.addEventListener(
      'click',
      (e) => {
        e.preventDefault()
        toggle()
      },
      false
    )
  }

  close.addEventListener(
    'click',
    (e) => {
      e.preventDefault()
      toggle()
    },
    false
  )
}

export { workbook }
