import barba from "@barba/core";
import { gsap } from "gsap";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { bubbles } from "../components/bubbles";

const trial_10_3 = () => {
    delay(500).then(() => {

            // --------------------------------------------------
    // question variables
    // --------------------------------------------------
    let questionIndex = 10;

    // pagination
    paginationControls(questionIndex);

    bubbles();
    cluesInit("sfx_card.mp3", "spellbound");

    // --------------------------------------------------
    // slide navigation
    // --------------------------------------------------
    const saveAndContinue = (link) => { 
        barba.go(link); 
    };
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if(prev){
        prev.addEventListener("click", (e) => {
            e.preventDefault();
            saveAndContinue(e.target.href);
        });
    }
    if(next){
        next.addEventListener("click", (e) => {
            e.preventDefault();
            saveAndContinue(e.target.href);
        });
    }

});
    
};

export { trial_10_3 };
