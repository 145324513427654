import barba from '@barba/core'
import { delay, paginationControls } from '../components/utils'
import { cluesInit } from '../components/clue'
import {
    setStoreQuestionValue,
    getStoreQuestionValue,
} from '../components/stores'
import { audio } from '../components/audio'
import { bubbles } from '../components/bubbles'
import { dictionary } from '../components/dictionary'
import { navigationButtonsCallback } from '../components/navigationCallback'

const rotary_15 = () => {
    delay(500).then(() => {
        if (isDebugMode) {
            console.log('Rotary Q15: Loaded')
        }

        // --------------------------------------------------
        // ELEMENTS
        // --------------------------------------------------
        let textarea = document.querySelector('#answer_1')

        // --------------------------------------------------
        // QUESTION
        // --------------------------------------------------
        let questionIndex = 15
        let questionPart = 0

        // --------------------------------------------------
        // METHODS
        // --------------------------------------------------
        paginationControls(questionIndex)
        cluesInit()
        bubbles()
        audio()
        dictionary()

        // --------------------------------------------------
        // STORAGE
        // --------------------------------------------------

        // GET localStorage and render on the frontend
        let storedValue = getStoreQuestionValue(
            'assessment',
            'questions',
            questionIndex,
            questionPart
        )
        if (storedValue) {
            textarea.value = storedValue
        }

        // SET localStorage values for this question
        const setLocalStorage = () => {
            setStoreQuestionValue(
                'assessment',
                'questions',
                questionIndex,
                questionPart,
                textarea.value
            )
        }

        // --------------------------------------------------
        // NAVIGATION
        // --------------------------------------------------
        navigationButtonsCallback(setLocalStorage)
    })
}

export { rotary_15 }
