import barba from "@barba/core";
import { delay, stripNaughtyWords, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { bubbles } from "../components/bubbles";
import {
  setStoreQuestionValue,
  getStoreQuestionValue,
} from "../components/stores";

const trial_1_4 = () => {
  delay(500).then(() => {
    // --------------------------------------------------
    // question variables
    // --------------------------------------------------
    let questionIndex = 1;
    let questionPart = 2;

      // pagination
      paginationControls(questionIndex);

    // --------------------------------------------------
    // sanitise textarea
    // --------------------------------------------------
    const textarea = document.getElementById("working");
    stripNaughtyWords(textarea);

    // --------------------------------------------------
    // Functions
    // --------------------------------------------------
    cluesInit("sfx_open_diary.mp3", "spellbound");
    bubbles();

    // --------------------------------------------------
    // modify storage
    // --------------------------------------------------

    // get localStorage
    let storedValue = getStoreQuestionValue(
      "assessment",
      "questions",
      questionIndex,
      questionPart
    );
    if (storedValue) {
      textarea.value = storedValue;
    }

    // set localStorage
    const setLocalStorage = (link) => {
      setStoreQuestionValue(
        "assessment",
        "questions",
        questionIndex,
        questionPart,
        textarea.value
      );
      barba.go(link);
    };

    // --------------------------------------------------
    // events
    // --------------------------------------------------
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if (prev) {
      prev.addEventListener("click", (e) => {
        e.preventDefault();
        setLocalStorage(e.target.href);
      });
    }
    if (next) {
      next.addEventListener("click", (e) => {
        e.preventDefault();
        setLocalStorage(e.target.href);
      });
    }
  });
};

export { trial_1_4 };
