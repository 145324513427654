import barba from "@barba/core";
import { delay } from "../components/utils";
import { bubbles } from "../components/bubbles";

const trial_1_2 = () => {
  delay(500).then(() => {
    // message animations
    bubbles();
    // slide navigation
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if (prev) {
      prev.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
    if (next) {
      next.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
  });
};

export { trial_1_2 };
