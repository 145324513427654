import barba from "@barba/core";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { initNarrative } from "../components/narrative";
import { delay } from "../components/utils";
import { sFX } from "../components/sfx";

gsap.registerPlugin(Draggable, InertiaPlugin);

const trial_8_6 = () => {
  delay(500).then(() => {
    // narrative animations
    initNarrative();

    // drag and drop cards
    const droppables = gsap.utils.toArray(".box");
    const dropZones = gsap.utils.toArray(".boxDrop");
    const dropZone = document.querySelector(".js-dropzone");
    const overlapThreshold = "40%";
    let dropCount = 0;

    // randomise positions
    gsap.set(droppables, {
      x: gsap.utils.random(-256, 256, 4, true),
      y: gsap.utils.random(-256, 256, 4, true),
      rotate: gsap.utils.random(-8, 8, 4, true),
    });

    // dropables
    droppables.forEach((el) => {
      let insideZone = false;
      let isMatch = false;
      let rect1;
      let rect2;
      gsap.set(el, { rotation: gsap.utils.random(-5, 5, 5, true) });
      Draggable.create(el, {
        onDragStart: function () {
          sFX("spellbound/sfx_paper_piece.mp3");
        },
        onDrag: function () {
          insideZone = false;
          isMatch = false;
          for (var i = 0; i < dropZones.length; i++) {
            if (this.hitTest(dropZones[i], overlapThreshold)) {
              gsap.set(el, { rotation: 0 });
              insideZone = true;
              let dzValue = dropZones[i].dataset.letter;
              let elValue = this.target.dataset.letter;
              if (dzValue == elValue) {
                rect1 = dropZones[i].getBoundingClientRect();
                rect2 = this.target.getBoundingClientRect();
                isMatch = true;
              }
              break;
            }
          }
        },
        onDragEnd: function () {
          if (!insideZone) {
            gsap.to(this.target, { duration: 0.2, x: 0, y: 0 });
          }
          if (isMatch) {
            let x = "-=" + (rect2.left - rect1.left);
            let y = "-=" + (rect2.top - rect1.top);
            gsap.to(this.target, 0.3, {
              x: x,
              y: y,
              rotation: 0,
              onComplete: () => {
                // disable draggable
                Draggable.get(this.target).disable();
                // increment count
                dropCount++;
                // check count
                if (dropCount == 4) {
                  sFX("spellbound/sfx_puzzle_complete.mp3");
                  sfx_paper_piece
                  droppables.forEach((el) => el.classList.add("animate-pulse"));
                }
              },
            });
          } else {
            gsap.to(this.target, { duration: 0.3, x: 0, y: 0 });
          }
        },
      });
    });

    // slide navigation
    const next = document.querySelector(".js-next");
    const prev = document.querySelector(".js-prev");
    if (prev) {
      prev.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
    if (next) {
      next.addEventListener("click", (e) => {
        e.preventDefault();
        barba.go(e.target.href);
      });
    }
  });
};

export { trial_8_6 };
