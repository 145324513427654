// --------------------------------------------------
// Vendor
// --------------------------------------------------
import barba from '@barba/core'
import { gsap } from 'gsap'

// --------------------------------------------------
// Debug Mode
// --------------------------------------------------
window.isDebugMode = false

// --------------------------------------------------
// Shared Components
// --------------------------------------------------
import { fadeIn, fadeOut } from './components/transitions'
import {
  delay,
  setUserFrontEnd,
  backgroundImageScale,
  accessibilityMethods,
  getCurrentDateTime,
  disableBackButton,
} from './components/utils'
import { carousel } from './components/carousel'
import { initVideo } from './components/video'
import { initNarrative } from './components/narrative'
import { notLoggedIn } from './components/not-logged-in'
import { myBrowserCheck } from './components/browser'
import { initAccessibilityWidget } from './components/accessibility-widget'
import { cluesInit } from './components/clue'
import { sFX } from './components/sfx'
import {
  getStoreValue,
  getStoreObjValue,
  setStoreObjValue,
} from './components/stores'
import { encourager } from './components/encouragement'
import { bubbles } from './components/bubbles'
import { navigationButtonsBasic } from './components/navigation'
import { audio } from './components/audio'
import { dictionary } from './components/dictionary'
import { complete_assessment } from './components/complete_assessment'

// --------------------------------------------------
// Setup
// --------------------------------------------------
import { initMethodsHome } from './pages/home'
import { initMethodsSetup } from './pages/setup'
import { initMethodsAccessibility } from './pages/accessibility'
import { initLogo } from './pages/logo'

// --------------------------------------------------
// Modules
// --------------------------------------------------

// Escape the lab
import {
  escape_q1,
  escape_q2,
  escape_q3,
  escape_q4,
  escape_q5,
  escape_q6,
  escape_q7,
  escape_q8,
  escape_q9,
  escape_q10,
  escape_q11,
  escape_q12,
  escape_q13,
  escape_q14,
  escape_q15,
  escape_q16,
  escape_q17,
  escape_q18,
  escape_q19,
  escape_q20,
  escape_q21,
} from './escape-the-lab/app'

// Spellbound
import {
  trial_1_2,
  trial_1_3,
  trial_1_4,
  trial_1_5,
  trial_2_3,
  trial_3_2,
  trial_3_3,
  trial_3_4,
  trial_4_3,
  trial_4_4,
  trial_5_2,
  trial_5_3,
  trial_5_4,
  trial_5_5,
  trial_5_6,
  trial_6_2,
  trial_7_3,
  trial_8_2,
  trial_8_3,
  trial_8_4,
  trial_8_5,
  trial_8_6,
  trial_9_2,
  trial_9_3,
  trial_9_4,
  trial_10_3,
  trial_10_4,
  trial_2_4,
} from './spellbound/app'

// Mythias
import {
  mythias_mission_1,
  mythias_mission_2,
  mythias_mission_3,
  mythias_mission_4,
  mythias_mission_5,
  mythias_mission_6,
  mythias_mission_7,
  mythias_mission_8,
  mythias_mission_9,
  mythias_mission_10,
  mythias_mission_11,
  mythias_mission_12,
  mythias_mission_13,
  mythias_mission_14,
  mythias_mission_15,
  mythias_mission_16,
  mythias_mission_17,
  mythias_mission_18,
  mythias_mission_19,
} from './mythias/app'

// Rotary
import {
  rotary_1,
  rotary_2,
  rotary_3,
  rotary_4,
  rotary_5,
  rotary_6,
  rotary_7,
  rotary_8,
  rotary_9,
  rotary_10,
  rotary_11,
  rotary_12,
  rotary_13,
  rotary_14,
  rotary_15,
  rotary_16,
  rotary_17,
  rotary_18,
  rotary_19,
  rotary_20,
} from './rotary/app'

// --------------------------------------------------
// Common Methods
// --------------------------------------------------

const myNarrative = () => {
  delay(500).then(() => {
    backgroundImageScale()
    initNarrative()
    bubbles()
    cluesInit()
    navigationButtonsBasic()
    audio()
    dictionary()
  })
}

const playBgVideo = (DOM) => {
  let videoElem = DOM.querySelector('#backgroundVideo')
  if (videoElem) {
    let playPromise = videoElem.play()
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          if (isDebugMode) {
            console.log('Playing Video...')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

// --------------------------------------------------
// Barba.js
// --------------------------------------------------

// beforeLeave
barba.hooks.beforeLeave((data) => {
  if (isDebugMode) {
    console.log(`beforeLeave hook : ${data.current.namespace}`)
  }
  gsap.killTweensOf('.js-words')
  gsap.killTweensOf('.js-message')
})

// beforeEnter
barba.hooks.beforeEnter((data) => {
  if (isDebugMode) {
    console.log(`beforeEnter hook : ${data.next.namespace}`)
  }
  notLoggedIn(data.next.namespace)
  myBrowserCheck()
  accessibilityMethods()
  encourager()
})

// afterEnter
barba.hooks.afterEnter((data) => {
  if (isDebugMode) {
    console.log(`afterEnter hook : ${data.next.namespace}`)
    console.log(getStoreValue('assessment'))
  }
  initAccessibilityWidget()
  playBgVideo(data.next.container)
})

// Create a barba debugging object
let barbaOptions = {}
if (isDebugMode) {
  barbaOptions = {
    debug: true,
    logLevel: 'error',
  }
}

// init
barba.init({
  ...barbaOptions,
  timeout: 5000,
  cacheIgnore: true,
  transitions: [
    {
      name: 'general-transition',
      leave: ({ current }) => fadeOut(current.container),
      enter: ({ next }) => fadeIn(next.container),
    },
  ],
  views: [
    {
      namespace: 'home',
      afterEnter() {
        initLogo()
      },
    },
    {
      namespace: 'login',
      afterEnter() {
        initMethodsHome()
      },
    },
    {
      namespace: 'setup',
      afterEnter() {
        initMethodsSetup()
      },
    },
    {
      namespace: 'accessibility',
      afterEnter() {
        initMethodsAccessibility()
      },
    },
    {
      namespace: 'start',
      afterEnter() {
        let theSubject = getStoreObjValue('assessment', 'subject')
        delay(100).then(() => {
          setUserFrontEnd()
          disableBackButton()
          document.querySelector('.js-next').addEventListener('click', () => {
            sFX('sfx/sfx-clue.mp3')
            let url =
              theSubject == 'Maths'
                ? '/app/assessment-maths.php'
                : '/app/assessment-english.php'
            barba.go(url)
          })
        })
      },
    },
    {
      namespace: 'assessment-english',
      afterEnter() {
        delay(100).then(() => {
          carousel()
          document.querySelector('.js-next').addEventListener('click', () => {
            sFX('sfx/sfx-clue.mp3')
            barba.go(getStoreValue('module'))
          })
        })
      },
    },
    {
      namespace: 'assessment-maths',
      afterEnter() {
        delay(100).then(() => {
          carousel()
          document.querySelector('.js-next').addEventListener('click', () => {
            sFX('sfx/sfx-clue.mp3')
            barba.go(getStoreValue('module'))
          })
        })
      },
    },
    // --------------------------------------------------
    // Module: Spellbound
    // --------------------------------------------------
    {
      namespace: 'spellbound_video',
      afterEnter() {
        initVideo()
      },
    },
    {
      namespace: 'spellbound_guide',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_complete',
      afterEnter() {
        complete_assessment(
          'https://escape-assessment.co.uk/wp-json/really-neet-plugin/v1/english/post',
          'https://s.surveyplanet.com/z3jq4k6k'
        )
      },
    },
    {
      namespace: 'spellbound_trial_0_1',
      afterEnter() {
        if (getStoreObjValue('assessment', 'started') == '') {
          let now = getCurrentDateTime()
          setStoreObjValue('assessment', 'started', now)
        }
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_0_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_1_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_1_2',
      afterEnter() {
        trial_1_2()
      },
    },
    {
      namespace: 'spellbound_trial_1_3',
      afterEnter() {
        trial_1_3()
      },
    },
    {
      namespace: 'spellbound_trial_1_4',
      afterEnter() {
        trial_1_4()
      },
    },
    {
      namespace: 'spellbound_trial_1_5',
      afterEnter() {
        trial_1_5()
      },
    },
    {
      namespace: 'spellbound_trial_2_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_2_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_2_3',
      afterEnter() {
        trial_2_3()
      },
    },
    {
      namespace: 'spellbound_trial_2_4',
      afterEnter() {
        trial_2_4()
      },
    },
    {
      namespace: 'spellbound_trial_3_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_3_2',
      afterEnter() {
        trial_3_2()
      },
    },
    {
      namespace: 'spellbound_trial_3_3',
      afterEnter() {
        trial_3_3()
      },
    },
    {
      namespace: 'spellbound_trial_3_4',
      afterEnter() {
        trial_3_4()
      },
    },
    {
      namespace: 'spellbound_trial_4_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_4_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_4_3',
      afterEnter() {
        trial_4_3()
      },
    },
    {
      namespace: 'spellbound_trial_4_4',
      afterEnter() {
        trial_4_4()
      },
    },
    {
      namespace: 'spellbound_trial_5_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_5_2',
      afterEnter() {
        trial_5_2()
      },
    },
    {
      namespace: 'spellbound_trial_5_3',
      afterEnter() {
        trial_5_3()
      },
    },
    {
      namespace: 'spellbound_trial_5_4',
      afterEnter() {
        trial_5_4()
      },
    },
    {
      namespace: 'spellbound_trial_5_5',
      afterEnter() {
        trial_5_5()
      },
    },
    {
      namespace: 'spellbound_trial_5_6',
      afterEnter() {
        trial_5_6()
      },
    },
    {
      namespace: 'spellbound_trial_6_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_6_2',
      afterEnter() {
        trial_6_2()
      },
    },
    {
      namespace: 'spellbound_trial_7_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_7_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_7_3',
      afterEnter() {
        trial_7_3()
      },
    },
    {
      namespace: 'spellbound_trial_8_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_8_2',
      afterEnter() {
        trial_8_2()
      },
    },
    {
      namespace: 'spellbound_trial_8_3',
      afterEnter() {
        trial_8_3()
      },
    },
    {
      namespace: 'spellbound_trial_8_4',
      afterEnter() {
        trial_8_4()
      },
    },
    {
      namespace: 'spellbound_trial_8_5',
      afterEnter() {
        trial_8_5()
      },
    },
    {
      namespace: 'spellbound_trial_8_6',
      afterEnter() {
        trial_8_6()
      },
    },
    {
      namespace: 'spellbound_trial_9_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_9_2',
      afterEnter() {
        trial_9_2()
      },
    },
    {
      namespace: 'spellbound_trial_9_3',
      afterEnter() {
        trial_9_3()
      },
    },
    {
      namespace: 'spellbound_trial_9_4',
      afterEnter() {
        trial_9_4()
      },
    },
    {
      namespace: 'spellbound_trial_10_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_10_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'spellbound_trial_10_3',
      afterEnter() {
        trial_10_3()
      },
    },
    {
      namespace: 'spellbound_trial_10_4',
      afterEnter() {
        trial_10_4()
      },
    },
    {
      namespace: 'spellbound_trial_10_5',
      afterEnter() {
        myNarrative()
      },
    },
    // --------------------------------------------------
    // Module: Escape the Lab
    // --------------------------------------------------
    {
      namespace: 'escape_video',
      afterEnter() {
        initVideo()
      },
    },
    {
      namespace: 'escape_guide',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_complete',
      afterEnter() {
        complete_assessment(
          'https://escape-assessment.co.uk/wp-json/really-neet-plugin/v1/maths/post',
          'https://s.surveyplanet.com/ttam78ht'
        )
      },
    },
    {
      namespace: 'escape_q1_narrative_1',
      afterEnter() {
        myNarrative()
        if (getStoreObjValue('assessment', 'started') == '') {
          let now = getCurrentDateTime()
          setStoreObjValue('assessment', 'started', now)
        }
      },
    },
    {
      namespace: 'escape_q1_narrative_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q2_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q3_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q4_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q5_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q6_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q7_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q7_narrative_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q8_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q9_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q10_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q10_narrative_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q12_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q13_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q13_narrative_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q16_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q17_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q18_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q19_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q20_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q21_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q22_narrative_1',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q22_narrative_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'escape_q1',
      afterEnter() {
        escape_q1()
      },
    },
    {
      namespace: 'escape_q2',
      afterEnter() {
        escape_q2()
      },
    },
    {
      namespace: 'escape_q3',
      afterEnter() {
        escape_q3()
      },
    },
    {
      namespace: 'escape_q4',
      afterEnter() {
        escape_q4()
      },
    },
    {
      namespace: 'escape_q5',
      afterEnter() {
        escape_q5()
      },
    },
    {
      namespace: 'escape_q6',
      afterEnter() {
        escape_q6()
      },
    },
    {
      namespace: 'escape_q7',
      afterEnter() {
        escape_q7()
      },
    },
    {
      namespace: 'escape_q8',
      afterEnter() {
        escape_q8()
      },
    },
    {
      namespace: 'escape_q9',
      afterEnter() {
        escape_q9()
      },
    },
    {
      namespace: 'escape_q10',
      afterEnter() {
        escape_q10()
      },
    },
    {
      namespace: 'escape_q11',
      afterEnter() {
        escape_q11()
      },
    },
    {
      namespace: 'escape_q12',
      afterEnter() {
        escape_q12()
      },
    },
    {
      namespace: 'escape_q13',
      afterEnter() {
        escape_q13()
      },
    },
    {
      namespace: 'escape_q14',
      afterEnter() {
        escape_q14()
      },
    },
    {
      namespace: 'escape_q15',
      afterEnter() {
        escape_q15()
      },
    },
    {
      namespace: 'escape_q16',
      afterEnter() {
        escape_q16()
      },
    },
    {
      namespace: 'escape_q17',
      afterEnter() {
        escape_q17()
      },
    },
    {
      namespace: 'escape_q18',
      afterEnter() {
        escape_q18()
      },
    },
    {
      namespace: 'escape_q19',
      afterEnter() {
        escape_q19()
      },
    },
    {
      namespace: 'escape_q20',
      afterEnter() {
        escape_q20()
      },
    },
    {
      namespace: 'escape_q21',
      afterEnter() {
        escape_q21()
      },
    },
    // --------------------------------------------------
    // Module: Mythias
    // --------------------------------------------------
    {
      namespace: 'mythias_guide',
      afterEnter() {
        delay(500).then(() => myNarrative())
      },
    },
    {
      namespace: 'mythias_video',
      afterEnter() {
        initVideo()
      },
    },
    {
      namespace: 'mythias_complete',
      afterEnter() {
        complete_assessment(
          'https://escape-assessment.co.uk/wp-json/really-neet-plugin/v1/maths/post',
          'https://s.surveyplanet.com/xlff1w07'
        )
      },
    },
    {
      namespace: 'mythias_narrative_1a',
      afterEnter() {
        delay(500).then(() => {
          myNarrative()
          if (getStoreObjValue('assessment', 'started') == '') {
            let now = getCurrentDateTime()
            setStoreObjValue('assessment', 'started', now)
          }
        })
      },
    },
    {
      namespace: 'mythias_narrative_1b',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_1c',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_mission_1',
      afterEnter() {
        mythias_mission_1()
      },
    },
    {
      namespace: 'mythias_mission_2',
      afterEnter() {
        mythias_mission_2()
      },
    },
    {
      namespace: 'mythias_mission_3',
      afterEnter() {
        mythias_mission_3()
      },
    },
    {
      namespace: 'mythias_mission_4',
      afterEnter() {
        mythias_mission_4()
      },
    },
    {
      namespace: 'mythias_mission_5',
      afterEnter() {
        mythias_mission_5()
      },
    },
    {
      namespace: 'mythias_mission_6',
      afterEnter() {
        mythias_mission_6()
      },
    },
    {
      namespace: 'mythias_mission_7',
      afterEnter() {
        mythias_mission_7()
      },
    },
    {
      namespace: 'mythias_mission_8',
      afterEnter() {
        mythias_mission_8()
      },
    },
    {
      namespace: 'mythias_mission_9',
      afterEnter() {
        mythias_mission_9()
      },
    },
    {
      namespace: 'mythias_mission_10',
      afterEnter() {
        mythias_mission_10()
      },
    },
    {
      namespace: 'mythias_mission_11',
      afterEnter() {
        mythias_mission_11()
      },
    },
    {
      namespace: 'mythias_mission_12',
      afterEnter() {
        mythias_mission_12()
      },
    },
    {
      namespace: 'mythias_mission_13',
      afterEnter() {
        mythias_mission_13()
      },
    },
    {
      namespace: 'mythias_mission_14',
      afterEnter() {
        mythias_mission_14()
      },
    },
    {
      namespace: 'mythias_mission_15',
      afterEnter() {
        mythias_mission_15()
      },
    },
    {
      namespace: 'mythias_mission_16',
      afterEnter() {
        mythias_mission_16()
      },
    },
    {
      namespace: 'mythias_mission_17',
      afterEnter() {
        mythias_mission_17()
      },
    },
    {
      namespace: 'mythias_mission_18',
      afterEnter() {
        mythias_mission_18()
      },
    },
    {
      namespace: 'mythias_mission_19',
      afterEnter() {
        mythias_mission_19()
      },
    },
    {
      namespace: 'mythias_narrative_2a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_3a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_4a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_5a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_6a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_8a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_9a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_10a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_11a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_13a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_14a',
      afterEnter() {
        delay(500).then(() => {
          myNarrative()
        })
      },
    },
    {
      namespace: 'mythias_narrative_16a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_17a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_19a',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'mythias_narrative_19b',
      afterEnter() {
        myNarrative()
      },
    },
    // --------------------------------------------------
    // Module: Rotary
    // --------------------------------------------------
    {
      namespace: 'rotary_guide',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_video',
      afterEnter() {
        initVideo()
      },
    },
    {
      namespace: 'rotary_complete',
      afterEnter() {
        complete_assessment(
          'https://escape-assessment.co.uk/wp-json/really-neet-plugin/v1/english/post',
          'https://s.surveyplanet.com/zfgxgejg'
        )
      },
    },
    {
      namespace: 'rotary_slide_1',
      afterEnter() {
        if (getStoreObjValue('assessment', 'started') == '') {
          let now = getCurrentDateTime()
          setStoreObjValue('assessment', 'started', now)
        }
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_2',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_3',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_4',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_5',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_6',
      afterEnter() {
        rotary_1()
      },
    },
    {
      namespace: 'rotary_slide_7',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_8',
      afterEnter() {
        rotary_2()
      },
    },
    {
      namespace: 'rotary_slide_9',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_10',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_11',
      afterEnter() {
        rotary_3()
      },
    },
    {
      namespace: 'rotary_slide_12',
      afterEnter() {
        rotary_4()
      },
    },
    {
      namespace: 'rotary_slide_13',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_14',
      afterEnter() {
        rotary_5()
      },
    },
    {
      namespace: 'rotary_slide_15',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_16',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_17',
      afterEnter() {
        rotary_6()
      },
    },
    {
      namespace: 'rotary_slide_18',
      afterEnter() {
        rotary_7()
      },
    },
    {
      namespace: 'rotary_slide_19',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_20',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_21',
      afterEnter() {
        rotary_8()
      },
    },
    {
      namespace: 'rotary_slide_22',
      afterEnter() {
        rotary_9()
      },
    },
    {
      namespace: 'rotary_slide_23',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_24',
      afterEnter() {
        rotary_10()
      },
    },
    {
      namespace: 'rotary_slide_25',
      afterEnter() {
        rotary_11()
      },
    },
    {
      namespace: 'rotary_slide_26',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_27',
      afterEnter() {
        rotary_12()
      },
    },
    {
      namespace: 'rotary_slide_28',
      afterEnter() {
        rotary_13()
      },
    },
    {
      namespace: 'rotary_slide_29',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_30',
      afterEnter() {
        rotary_14()
      },
    },
    {
      namespace: 'rotary_slide_31',
      afterEnter() {
        rotary_15()
      },
    },
    {
      namespace: 'rotary_slide_32',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_33',
      afterEnter() {
        rotary_16()
      },
    },
    {
      namespace: 'rotary_slide_34',
      afterEnter() {
        rotary_17()
      },
    },
    {
      namespace: 'rotary_slide_35',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_36',
      afterEnter() {
        rotary_18()
      },
    },
    {
      namespace: 'rotary_slide_37',
      afterEnter() {
        rotary_19()
      },
    },
    {
      namespace: 'rotary_slide_38',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_39',
      afterEnter() {
        myNarrative()
      },
    },
    {
      namespace: 'rotary_slide_40',
      afterEnter() {
        myNarrative()
      },
    },
  ],
})
