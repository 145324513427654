import { gsap } from 'gsap'

const shake = (el) => {
    return new Promise((resolve) => {
        let tl = gsap.timeline({
            onComplete: () => {
                gsap.to(el, {
                    duration: 0.4,
                    x: 0,
                    ease: 'power4',
                    onComplete: resolve,
                })
            },
        })
        tl.to(el, {
            duration: 0.06,
            x: gsap.utils.clamp(-24, 24, 10, true),
            yoyo: true,
            repeat: 5,
            ease: 'sine.out',
        })
    })
}

export { shake }
