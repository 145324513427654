import { gsap } from 'gsap'
import { sFX } from '../components/sfx'

const getDictionary = (word) => {
    let container = document.getElementById('dictionary__output')
    let is404 = false

    // api request
    let response = fetch(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`,
        {
            method: 'GET',
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json' },
        }
    )
        .then((response) => {
            if (!response.status || response.status > 200) {
                container.innerHTML = 'Sorry, nothing found.'
                is404 = true
                return
            }
            return response.json()
        })

        .then((response) => {
            if (isDebugMode) {
                console.log(response)
            }

            if (is404) {
                return
            }

            let count = 0,
                content = ''
            // build content string
            for (const [key, value] of Object.entries(response)) {
                if (count == 0) {
                    content += "<div class='flex justify-between items-center'>"
                    if (
                        value.phonetics[0] === undefined ||
                        value.phonetics[0].length == 0
                    ) {
                        content += `<h2 class="m-0 text-2xl font-bold">${value.word}</h2>`
                    } else {
                        content += `<h2 class="m-0 text-2xl font-bold">`
                        content += `${value.word}`
                        if (value.phonetics[0].text) {
                            content += `(${value.phonetics[0].text})`
                        }
                        content += '</h2>'
                        if (value.phonetics[0].audio) {
                            content += `<audio controls src="${value.phonetics[0].audio}"></audio>`
                        }
                    }
                    content += '</div>'
                    count++
                }
                for (const item of value.meanings) {
                    content += `<p class="font-bold m-0">${item.partOfSpeech}</p>`
                    if (item.definitions) {
                        content += "<ul class='space-y-2 leading-tight'>"
                        for (const def of item.definitions) {
                            content += `<li class="italic">${def.definition}</li>`
                        }
                        content += '</ul>'
                    }
                }
            }
            // render
            container.innerHTML = content
        })

        .catch((err) => {
            console.log(err)
        })
}

const dictionary = () => {
    // DOM elements
    let dict = document.getElementById('dictionary')
    if (!dict) return

    let dictForm = document.getElementById('dictionary__form')
    let dictFormInput = document.getElementById('dictionary__input')
    let dictClose = document.getElementById('dictionary__close')
    let dictOpen = document.getElementById('dictionary__open')

    // set default state
    gsap.set(dict, { autoAlpha: 0, scale: 0.4 })

    // create timeline
    let timeline = gsap.timeline()

    // animations
    const dictionaryAnimation = (dir) => {
        sFX('sfx/sfx-clue.mp3')
        timeline.clear()
        timeline.to(dict, {
            duration: 0.4,
            autoAlpha: dir ? 1 : 0,
            scale: dir ? 1 : 0.4,
            pointerEvents: dir ? 'all' : 'none',
            ease: dir ? 'back.out' : 'back.inOut',
        })
    }

    // triggers
    dictClose.addEventListener('click', (e) => dictionaryAnimation(0))
    dictOpen.addEventListener('click', (e) => dictionaryAnimation(1))

    // fire api request
    dictForm.addEventListener('submit', (e) => {
        e.preventDefault()
        getDictionary(dictFormInput.value)
    })
}

export { dictionary }
