import barba from '@barba/core'
import { sFX } from './sfx'

const navigationButtonsCallback = (callbackFunction) => {
    let next = document.querySelector('.js-next')
    let prev = document.querySelector('.js-prev')
    if (next) {
        next.addEventListener('click', (e) => {
            e.preventDefault()
            sFX('sfx/RockTap_BW.53729.mp3')
            callbackFunction()
            barba.go(e.target.href)
        })
    }
    if (prev) {
        prev.addEventListener('click', (e) => {
            e.preventDefault()
            sFX('sfx/RockTap_BW.53729.mp3')
            callbackFunction()
            barba.go(e.target.href)
        })
    }
}

export { navigationButtonsCallback }
