import imagesLoaded from 'imagesloaded'
import { gsap } from 'gsap'
import { getStoreObjValue, getStoreValue, setStoreValue } from './stores'

// --------------------------------------------------
// preloader
// --------------------------------------------------
const preloadImages = (selector = 'img') => {
  return new Promise((resolve) => {
    let imgLoad = imagesLoaded(selector, { background: true })
    imgLoad.on('done', function (instance) {
      resolve()
    })
  })
}

// --------------------------------------------------
// Get the highest zIndex of any DOM element and add one to it.
// --------------------------------------------------
const getHighestZIndex = (elem) => {
  const elements = document.getElementsByTagName('*')
  let highestZIndex = 0
  for (let i = 0; i < elements.length; i++) {
    const zIndex = parseInt(
      window.getComputedStyle(elements[i]).getPropertyValue('z-index')
    )
    if (zIndex > highestZIndex) {
      highestZIndex = zIndex
    }
  }
  gsap.set(elem, { zIndex: highestZIndex + 1 })
}

// --------------------------------------------------
// delay
// --------------------------------------------------
const delay = (n) => {
  n = n || 2000
  return new Promise((done) => {
    setTimeout(() => {
      done()
    }, n)
  })
}

// --------------------------------------------------
// background image scale animation
// --------------------------------------------------
const backgroundImageScale = () => {
  let bgImage = document.querySelector('#backgroundImage')
  if (bgImage) {
    gsap.set(bgImage, { autoAlpha: 1 })
    gsap.to(bgImage, {
      duration: 4,
      scale: 1.05,
      yoyo: true,
      repeat: -1,
    })
  }
}

// --------------------------------------------------
// set user name on front end
// --------------------------------------------------
const setUserFrontEnd = () => {
  let user = getStoreObjValue('student', 'name')
  if (user) {
    document
      .querySelectorAll('.js-name')
      .forEach((el) => (el.textContent = user))
  }
}

// --------------------------------------------------
// apply video captions
// --------------------------------------------------
const applyVideoCaptions = () => {
  if (getStoreObjValue('accessibility', 'captions') === 'true') {
    const video = document.getElementById('video')
    if (video) {
      const source = document.querySelector('video > source').src
      let parts = source.split('.')
      let sourcePath = `${parts[0]}_captions.${parts[1]}`
      video.setAttribute('src', sourcePath)
    }
  }
}

// --------------------------------------------------
// apply font sizes TODO
// --------------------------------------------------
const applyFontSizes = () => {
  if (isDebugMode) {
    console.log('Applying text size changes...')
  }
  let value = getStoreObjValue('accessibility', 'fontSize')
  if (value !== null) {
    let elems = document.querySelectorAll('.prose, .prose-lg, .prose-xl')
    if (value == 'normal') {
      document.body.classList.remove('text-xl')
      document.body.classList.remove('text-lg')
      document.body.classList.add('text-base')
      elems.forEach((x) => {
        x.classList.remove('prose')
        x.classList.remove('prose-lg')
        x.classList.remove('prose-xl')
        x.classList.add('prose')
      })
    }
    if (value == 'large') {
      document.body.classList.remove('text-xl')
      document.body.classList.remove('text-base')
      document.body.classList.add('text-lg')
      elems.forEach((x) => {
        x.classList.remove('prose')
        x.classList.remove('prose-lg')
        x.classList.remove('prose-xl')
        x.classList.add('prose-lg')
      })
    }
    if (value == 'extralarge') {
      document.body.classList.remove('text-base')
      document.body.classList.remove('text-lg')
      document.body.classList.add('text-xl')
      elems.forEach((x) => {
        x.classList.remove('prose')
        x.classList.remove('prose-lg')
        x.classList.remove('prose-xl')
        x.classList.add('prose-xl')
      })
    }
  }
}

// --------------------------------------------------
// apply dyslexic font
// --------------------------------------------------
const applyDyslexicFont = () => {
  if (isDebugMode) {
    console.log('Applying dyslexic font...')
  }
  if (getStoreObjValue('accessibility', 'dyslexic') === 'true') {
    let elems = document.querySelectorAll(
      '.font-sans, .font-lora, .font-orbitron, .font-play, .font-specialElite, .font-serif, .font-greatVibes, .font-rotary, .font-garamond'
    )
    elems.forEach((x) => (x.className += ' !font-dyslexic'))
  } else {
    let elems = document.getElementsByClassName('!font-dyslexic')
    ;[...elems].forEach((x) => x.classList.remove('!font-dyslexic'))
  }
}

// --------------------------------------------------
// apply background colours TODO
// --------------------------------------------------
const applyBoxBgColors = () => {
  if (isDebugMode) {
    console.log('Applying background colors...')
  }
  let bgColor = getStoreObjValue('accessibility', 'pageColor')
  console.log(bgColor)
  if (bgColor !== null) {
    let bgColorElems = document.querySelectorAll('.js-accessibilityColor')
    let msgParts = document.querySelectorAll('.js-messagePart')
    let msgPartsDibber = document.querySelectorAll('.js-messagePartDibber')
    let preTitles = document.querySelectorAll('.js-mythias-pretitle')

    // remove existing styles
    const removeStyle = () => {
      bgColorElems.forEach((t) => {
        t.classList.remove(
          'bg-yellow-400',
          'bg-blue-400',
          'bg-green-400',
          'bg-red-400',
          'bg-black',
          'bg-white',
          'text-white',
          'text-black'
        )
      })
      msgParts.forEach((t) => {
        t.classList.remove(
          '!bg-yellow-400',
          '!bg-blue-400',
          '!bg-green-400',
          '!bg-red-400',
          '!bg-black',
          '!bg-white',
          '!text-white',
          '!text-black'
        )
      })
      msgPartsDibber.forEach((t) => {
        t.classList.remove(
          '!fill-yellow-400',
          '!fill-blue-400',
          '!fill-green-400',
          '!fill-red-400',
          '!fill-white',
          '!fill-black'
        )
      })
      preTitles.forEach((t) => {
        t.classList.remove('!text-white', '!text-black')
      })
    }
    removeStyle()

    switch (bgColor) {
      case 'red':
        bgColorElems.forEach((t) => {
          t.classList.add('bg-red-400', 'text-black')
        })
        if (msgParts) {
          msgParts.forEach((t) => {
            t.classList.add('!bg-red-400', '!text-black')
          })
        }
        if (msgPartsDibber) {
          msgPartsDibber.forEach((t) => {
            t.classList.add('!fill-red-400')
          })
        }
        if (preTitles) {
          preTitles.forEach((t) => {
            t.classList.add('!text-black')
          })
        }
        break
      case 'blue':
        bgColorElems.forEach((t) => {
          t.classList.add('bg-blue-400', 'text-black')
        })
        if (msgParts) {
          msgParts.forEach((t) => {
            t.classList.add('!bg-blue-400', '!text-black')
          })
        }
        if (msgPartsDibber) {
          msgPartsDibber.forEach((t) => {
            t.classList.add('!fill-blue-400')
          })
        }
        if (preTitles) {
          preTitles.forEach((t) => {
            t.classList.add('!text-black')
          })
        }
        break
      case 'green':
        bgColorElems.forEach((t) => {
          t.classList.add('bg-green-400', 'text-black')
        })
        if (msgParts) {
          msgParts.forEach((t) => {
            t.classList.add('!bg-green-400', '!text-black')
          })
        }
        if (msgPartsDibber) {
          msgPartsDibber.forEach((t) => {
            t.classList.add('!fill-green-400')
          })
        }
        if (preTitles) {
          preTitles.forEach((t) => {
            t.classList.add('!text-black')
          })
        }
        break
      case 'black':
        bgColorElems.forEach((t) => {
          t.classList.add('bg-black', 'text-white')
        })
        if (msgParts) {
          msgParts.forEach((t) => {
            t.classList.add('!bg-black', '!text-white')
          })
        }
        if (msgPartsDibber) {
          msgPartsDibber.forEach((t) => {
            t.classList.add('!fill-black')
          })
        }
        break
      case 'yellow':
        bgColorElems.forEach((t) => {
          t.classList.add('bg-yellow-400', 'text-black')
        })
        if (msgParts) {
          msgParts.forEach((t) => {
            t.classList.add('!bg-yellow-400', '!text-black')
          })
        }
        if (msgPartsDibber) {
          msgPartsDibber.forEach((t) => {
            t.classList.add('!fill-yellow-400')
          })
        }
        if (preTitles) {
          preTitles.forEach((t) => {
            t.classList.add('!text-black')
          })
        }
        break
      default:
        removeStyle()
        let theModule = getStoreObjValue('assessment', 'module')
        let styles
        switch (theModule) {
          case 'escape':
            styles = ' bg-black text-white'
            break
          case 'mythias':
            styles = ' bg-green-400 text-white'
            break
          case 'rotary':
            styles = ' bg-black text-white'
            break
          default:
            styles = ' bg-white text-black'
            break
        }
        ;[...bgColorElems].forEach((t) => (t.className += styles))
    }

    // ;[...bgColorElems].forEach((t) =>
    //     t.classList.remove(
    //         'bg-yellow-400',
    //         'bg-blue-400',
    //         'bg-green-400',
    //         'bg-red-400',
    //         'bg-black',
    //         'text-white',
    //         'text-black'
    //     )
    // )
    // if (bgColor !== 'default') {
    //     [...bgColorElems].forEach(
    //         (t) => (t.className += ` ${bgColor} ${textColor}`)
    //     )
    // } else {
    //     let theModule = getStoreObjValue('assessment', 'module')
    //     let bg, text
    //     switch (theModule) {
    //         case 'escape':
    //             bg = 'bg-black'
    //             text = 'text-white'
    //             break
    //         case 'spellbound':
    //                 bg = 'bg-white'
    //                 text = 'text-black'
    //                 break
    //         case 'mythias':
    //                 bg = 'bg-black'
    //                 text = 'text-white'
    //                 break
    //         case 'rotary':
    //                 bg = 'bg-black'
    //                 text = 'text-white'
    //                 break
    //         default:
    //             bg = 'bg-white'
    //             text = 'text-black'
    //     }
    //     ;[...bgColorElems].forEach((t) => (t.className += ` ${bg} ${text}`))
    // }
  }
}

// --------------------------------------------------
// apply audio questions
// --------------------------------------------------
const applyAudio = () => {
  let elem = document.querySelector('[data-audio]')
  if (elem) {
    if (getStoreObjValue('accessibility', 'audio') === 'true') {
      elem.classList.remove('hidden')
    } else {
      elem.classList.add('hidden')
    }
  }
}

// --------------------------------------------------
// accessibility
// --------------------------------------------------
const accessibilityMethods = () => {
  applyBoxBgColors()
  applyDyslexicFont()
  applyFontSizes()
  applyVideoCaptions()
  applyAudio()
}

// --------------------------------------------------
// disable back button
// --------------------------------------------------
const disableBackButton = () => {
  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function () {
    window.history.go(1)
  }
}

// --------------------------------------------------
// swear word filter
// --------------------------------------------------
const swearWords = [
  'anal',
  'anus',
  'arse',
  'asshole',
  'ballsack',
  'balls',
  'bastard',
  'bitch',
  'biatch',
  'bloody',
  'blowjob',
  'blow job',
  'bollock',
  'bollok',
  'boner',
  'boob',
  'bugger',
  'bum',
  'butt',
  'buttplug',
  'clitoris',
  'cock',
  'coon',
  'crap',
  'cunt',
  'cum',
  'damn',
  'dick',
  'dickhead',
  'dildo',
  'dyke',
  'fag',
  'faggot',
  'fuckwit',
  'feck',
  'fellate',
  'fellatio',
  'felching',
  'fucking',
  'fuck',
  'f u c k',
  'fudgepacker',
  'fudge packer',
  'flange',
  'minge',
  'fanny',
  'Goddamn',
  'God damn',
  'homo',
  'jerk',
  'jizz',
  'knobhead',
  'knobend',
  'knob end',
  'labia',
  'lmao',
  'lmfao',
  'muff',
  'nigger',
  'nigga',
  'omg',
  'penis',
  'piss',
  'poop',
  'prick',
  'pube',
  'pussy',
  'queer',
  'scrotum',
  'sex',
  'shit',
  's hit',
  'sh1t',
  'slut',
  'slag',
  'skank',
  'smegma',
  'spunk',
  'tit',
  'tosser',
  'tossa',
  'tranny',
  'turd',
  'twat',
  'vagina',
  'wanker',
  'wank',
  'whore',
  'vag',
]

const stripNaughtyWords = (el) => {
  const filterSwearWords = () => {
    swearWords.forEach((word) => {
      el.value = el.value.replaceAll(word, '')
    })
  }
  el.addEventListener('change', filterSwearWords)
}

// --------------------------------------------------
// get timestamp
// --------------------------------------------------
const getCurrentDateTime = () => {
  let today = new Date()
  let year = today.getFullYear()
  let month = today.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let day = today.getDate()
  let hour = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
  let min =
    today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
  let sec =
    today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()
  return `${hour}:${min}:${sec} ${day}/${month}/${year}`
}

// --------------------------------------------------
// Captialise
// --------------------------------------------------
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// --------------------------------------------------
// Pagination
// --------------------------------------------------
const paginationControls = (page) => {
  // get array
  let viewedQuestions = getStoreValue('viewedQuestions')
  if (isDebugMode) {
    console.log(`Viewed Questions: ${viewedQuestions}`)
    console.log(`Current Question: ${page}`)
  }
  // if we have a page passed through
  if (page && Number.isInteger(page)) {
    // if the array doesn't exist
    if (!viewedQuestions) {
      // create it
      setStoreValue('viewedQuestions', [])
      let viewedQuestions = getStoreValue('viewedQuestions')
      if (!viewedQuestions.includes(page)) {
        viewedQuestions.push(page)
        setStoreValue('viewedQuestions', viewedQuestions)
      }
    } else {
      // if it doesnt exist, check current page hasn't already been viewed
      if (!viewedQuestions.includes(page)) {
        viewedQuestions.push(page)
        setStoreValue('viewedQuestions', viewedQuestions)
      }
      // get all pagination
      let anchors = gsap.utils.toArray('.js-pagination a')
      anchors.forEach((a, index) => {
        // get span
        let span = a.querySelector('span')
        // check if current page index matches that of array
        if (viewedQuestions.includes(index + 1)) {
          // add a viewed state
          span.classList.remove('hidden')
          a.href = a.dataset.url
        }
      })
    }
  }
}

// --------------------------------------------------
// export
// --------------------------------------------------
export {
  delay,
  preloadImages,
  setUserFrontEnd,
  backgroundImageScale,
  accessibilityMethods,
  applyDyslexicFont,
  getCurrentDateTime,
  applyVideoCaptions,
  applyBoxBgColors,
  applyFontSizes,
  disableBackButton,
  paginationControls,
  stripNaughtyWords,
  getHighestZIndex,
}
