// --------------------------------------------------
// Create Local Storage
// --------------------------------------------------

const createStore = () => {
    let loggedIn = false
    let seenTutorial = false
    let accessibility = {
        captions: null,
        audio: null,
        dyslexic: null,
        fontSize: 'normal',
        pageColor: null,
    }
    let student = {
        id: null,
        name: null,
    }
    let teacher = {
        id: null,
        email: null,
    }
    const objects = {}
    for (let x = 1; x < 22; x++) {
        objects[x] = {}
    }
    let assessment = {
        started: '',
        ended: '',
        subject: '',
        centre: '',
        module: '',
        paper: '',
        questions: { ...objects },
    }
    localStorage.setItem('accessibility', JSON.stringify(accessibility))
    localStorage.setItem('student', JSON.stringify(student))
    localStorage.setItem('teacher', JSON.stringify(teacher))
    localStorage.setItem('assessment', JSON.stringify(assessment))
    localStorage.setItem('loggedIn', loggedIn)
    localStorage.setItem('seenTutorial', seenTutorial)
}

// --------------------------------------------------
// Manipulate Stores
// --------------------------------------------------

// setters
const setStoreValue = (item, value) => {
    localStorage.setItem(item, JSON.stringify(value))
}
const setStoreObjValue = (obj, item, value) => {
    let object = localStorage.getItem(obj)
    object = object ? JSON.parse(object) : {}
    object[item] = value
    localStorage.setItem(obj, JSON.stringify(object))
}
const setStoreQuestionValue = (obj, item, field, index, value) => {
    let object = localStorage.getItem(obj)
    object = object ? JSON.parse(object) : {}
    Object.assign(object[item][field], { [index]: { answer: value } })
    localStorage.setItem(obj, JSON.stringify(object))
}

// getters
const getStoreValue = (obj) => {
    return JSON.parse(localStorage.getItem(obj))
}
const getStoreObjValue = (obj, item) => {
    let object = localStorage.getItem(obj)
    object = object ? JSON.parse(object) : {}
    return object[item]
}
const getStoreQuestionValue = (obj, item, field, index) => {
    let object = localStorage.getItem(obj)
    object = object ? JSON.parse(object) : {}
    if (
        typeof object[item][field][index] === 'undefined' ||
        object[item][field][index] === null
    ) {
        return false
    }
    return object[item][field][index].answer
}

// --------------------------------------------------
// Destroy Store
// --------------------------------------------------
const resetStore = () => {
    localStorage.clear()
}

// --------------------------------------------------
// Exports
// --------------------------------------------------

export {
    createStore,
    resetStore,
    setStoreValue,
    setStoreObjValue,
    setStoreQuestionValue,
    getStoreValue,
    getStoreObjValue,
    getStoreQuestionValue,
}
