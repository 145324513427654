import barba from "@barba/core";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { delay, paginationControls } from "../components/utils";
import { cluesInit } from "../components/clue";
import { initNarrative } from "../components/narrative";
import { wysiwyg } from "../components/wysiwyg";
import { workbook } from "../components/workbook";
import { calculator } from "../components/calculator";
import { setStoreQuestionValue, getStoreQuestionValue } from "../components/stores";
import { audio } from "../components/audio";
import { navigationButtonsCallback } from "../components/navigationCallback";

const mythias_mission_2 = () => {
  delay(500).then(() => {

    // --------------------------------------------------
    // ELEMENTS
    // --------------------------------------------------
    let textarea = document.querySelector("#working");
    let sliderVal = 0;

    // --------------------------------------------------
    // QUESTION
    // --------------------------------------------------
    let questionIndex = 2;
    let questionPart = 0;
    let questionPart2 = 1;

    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    paginationControls(questionIndex);
    calculator();
    cluesInit();
    initNarrative();
    wysiwyg();
    workbook();
    audio();

    // --------------------------------------------------
    // RANGE SLIDER
    // --------------------------------------------------
    let range = document.querySelector(".range");
    let output = document.querySelector(".js-probability");
    let rangeThumb = document.querySelector(".range__thumb");
    let w = range.getBoundingClientRect().width;
    let draggable_snap_distance = (w / 100) * 10;
    Draggable.create(rangeThumb, {
      type: "rotation",
      bounds: range,
      type: "x",
      liveSnap: function (value) {
        let snapTo = Math.round(value / draggable_snap_distance) * draggable_snap_distance;
        sliderVal = (snapTo / w).toFixed(1);
        console.log(sliderVal * 10);
        //output.textContent = sliderVal;
        output.textContent = `${sliderVal * 10}/10`;
        return snapTo;
      },
    });

    // --------------------------------------------------
    // STORAGE
    // --------------------------------------------------

    // GET localStorage and render on the frontend
    let storedValue = getStoreQuestionValue( "assessment", "questions", questionIndex, questionPart );
    let storedValue2 = getStoreQuestionValue( "assessment", "questions", questionIndex, questionPart2 );
    if (storedValue) {
      textarea.value = storedValue;
    }
    if(storedValue2) {
      sliderVal = storedValue2;
      gsap.set(rangeThumb, { x: sliderVal * w });
      //output.textContent = sliderVal;
      output.textContent = `${sliderVal * 10}/10`;
    }

    // SET localStorage values for this question
    const setLocalStorage = () => {
      setStoreQuestionValue( "assessment", "questions", questionIndex, questionPart, textarea.value );
      setStoreQuestionValue( "assessment", "questions", questionIndex, questionPart2, sliderVal );
    };

    // --------------------------------------------------
    // NAVIGATION
    // --------------------------------------------------
    navigationButtonsCallback(setLocalStorage);
  });

};

export { mythias_mission_2 };
