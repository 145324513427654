import barba from '@barba/core'
import { gsap } from 'gsap'
import { delay, accessibilityMethods } from './utils'
import { setStoreObjValue, getStoreObjValue } from './stores'
import { shake } from './shaker'
import { sFX } from './sfx'
import { customSelect } from './select'

const initAccessibilityWidget = () => {
    // custom select
    customSelect()

    delay(500).then(() => {
        if (isDebugMode) {
            console.log('Accessibility widget initialised...')
        }

        // elems
        let widgetOpen = document.querySelector('#accessibilityWidgetOpen')
        let widgetClose = document.querySelector('#accessibilityWidgetClose')
        let widget = document.querySelector('#accessibilityWidget')
        let formElement = document.querySelector('#accessibilityForm')
        let formElementLogin = document.querySelector(
            '#accessibilityWidgetLogin'
        )

        // animation
        if (widgetOpen) {
            const toggle = () => {
                sFX('sfx/sfx-clue.mp3')
                let x = widget.classList.contains('widget--open') ? 100 : -100
                let tl = gsap.timeline({
                    onComplete: () => {
                        widget.classList.toggle('widget--open')
                    },
                })
                tl.to(widget, {
                    duration: 0.6,
                    xPercent: x,
                    ease: 'power3.out',
                })
            }
            widgetOpen.addEventListener('click', toggle, false)
            widgetClose.addEventListener('click', toggle, false)

            // get stores
            document.querySelector('#accessibility_subtitles').checked =
                getStoreObjValue('accessibility', 'captions')
            document.querySelector('#accessibility_audio').checked =
                getStoreObjValue('accessibility', 'audio')
            document.querySelector('#accessibility_dyslexic').checked =
                getStoreObjValue('accessibility', 'dyslexic')
            document.querySelector('#accessibility_font_size').value =
                getStoreObjValue('accessibility', 'fontSize')
            document.querySelector('#accessibility_page_color').value =
                getStoreObjValue('accessibility', 'pageColor')

            // let mySelectedIndex = getStoreObjValue("accessibility", "pageColor");
            // document.querySelectorAll("[name=accessibility_page_color]").forEach((el) => {
            //   if (el.value == mySelectedIndex) { el.checked = mySelectedIndex; return;}
            // });

            // update stores
            const updateStores = () => {
                let formData = new FormData(formElement)
                setStoreObjValue(
                    'accessibility',
                    'captions',
                    formData.get('accessibility_subtitles')
                )
                setStoreObjValue(
                    'accessibility',
                    'audio',
                    formData.get('accessibility_audio')
                )
                setStoreObjValue(
                    'accessibility',
                    'dyslexic',
                    formData.get('accessibility_dyslexic')
                )
                setStoreObjValue(
                    'accessibility',
                    'fontSize',
                    formData.get('accessibility_font_size')
                )
                setStoreObjValue(
                    'accessibility',
                    'pageColor',
                    formData.get('accessibility_page_color')
                )
                accessibilityMethods()
                toggle()
                toggleClasses()
            }
            formElement.addEventListener('submit', (e) => {
                e.preventDefault()
                updateStores()
            })

            // show/hide widget
            const toggleClasses = () => {
                formElement.classList.toggle('hidden')
                formElementLogin.classList.toggle('hidden')
            }

            // form submit
            formElementLogin.addEventListener('submit', (e) => {
                e.preventDefault()
                let formData = new FormData(formElementLogin)
                if (formData.get('password') == 'adventure') {
                    toggleClasses()
                    formElementLogin.reset()
                } else {
                    shake('#accessibilityWidget form')
                }
            })
        }
    })
}

export { initAccessibilityWidget }
